import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query ErrorQuery {
    craft {
      globalSet(handle: "errorPage404") {
        ... on Craft_errorPage404_GlobalSet {
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          commonPlainHeader
          commonCopy
          commonCtaText
        }
      }
      entry(section: "home") {
        ... on Craft_home_home_Entry {
          homeAheadgroup {
            ... on Craft_homeAheadgroup_homeAheadGroupBlock_BlockType {
              id
              homeAheadGroupBlockBackgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              homeAheadGroupBlockSubtitle
              homeAheadGroupBlockTitle
              homeAheadGroupBlockDescription
              homeAheadGroupBlocklink {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// const previewQuery = `
// `;

const dataResolver = ({ craft }) => {
  const { heroTitle, heroImage, commonPlainHeader, commonCopy, commonCtaText } =
    craft.globalSet;

  const { homeAheadgroup } = craft.entry;

  const blocks = homeAheadgroup.map(block => {
    return {
      image: resolveImage(block.homeAheadGroupBlockBackgroundImage),
      subtitle: block.homeAheadGroupBlockSubtitle,
      title: block.homeAheadGroupBlockTitle,
      description: block.homeAheadGroupBlockDescription,
      url: block.homeAheadGroupBlocklink[0].url,
    };
  });

  return {
    hero: {
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    body: {
      heading: commonPlainHeader,
      copy: commonCopy,
      cta: commonCtaText,
    },
    ctas: { blocks },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
