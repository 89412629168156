import React from "react";
import { Heading } from "@molecules";
import { Container, Image } from "@atoms";

const CountyBusinesses = ({ heading, copy, images }) => {
  return (
    <div className="bg-white py-16 md:py-24">
      <Container variant="xl">
        <Heading heading={heading} copy={copy} />
        {images && (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 items-center bg-white mt-16 md:mt-24 p-12 shadow-lg">
            {images.map(image => (
              <Image image={image} objectFit="contain" transparent />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

CountyBusinesses.defaultProps = {};

export default CountyBusinesses;
