import React from "react";
import { Text, Icon, Button, Image, CbWrapper } from "@atoms";
import { AppLink } from "@base";

const PersonCard = ({ type, teamMember, cta, link }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-wrap items-center -mx-6">
        {/* person card */}
        <div className="w-full lg:w-1/2 relative flex justify-end items-center space-x-4 px-6">
          <div className="w-1/3">
            <Image
              ixParams={{ fit: "crop", crop: "faces" }}
              image={teamMember.image}
              ratio={1 / 1}
            />
          </div>
          <div className="flex flex-col w-2/3">
            <div className="flex items-center justify-start">
              <Text variant="title" className="text-blue">
                {teamMember.name}
              </Text>
              {teamMember.linkedIn && (
                <AppLink
                  to={teamMember.linkedIn}
                  className="w-4 h-4 ml-2 md:ml-4"
                >
                  <Icon name="linkedin" className="w-full text-blue" />
                </AppLink>
              )}
            </div>
            <Text variant="xs" className="text-gray font-bold">
              {teamMember.title}
            </Text>
            <AppLink
              to={`mailto:${teamMember.email}`}
              className="hover:text-blue transition duration-300 truncate"
            >
              <Text variant="xxs" className="text-gray">
                {teamMember.email}
              </Text>
            </AppLink>
          </div>
        </div>
        {/* cta and link */}
        <div className="w-full lg:w-1/2 px-6 space-y-4 mt-6 lg:mt-0">
          <Text variant="h2--small" className="text-blue">
            {cta}
          </Text>
          <Button to={link.url} size="md">
            {link.text}
          </Button>
        </div>
      </div>
    </CbWrapper>
  );
};

export default PersonCard;
