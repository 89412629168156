import React from "react";
import classNames from "classnames";
import { Heading, ImageCopyBlock } from "@molecules";
import { Container } from "@atoms";

const BlocksSection = ({
  heading,
  copy,
  blocks,
  className: _className,
  large,
}) => {
  return (
    <div className={classNames("py-16 md:py-24", _className)}>
      <Container variant="xl">
        <Heading heading={heading} copy={copy} />
        {blocks?.map((block, i) => (
          <ImageCopyBlock {...block} flip={i % 2 === 0} large={large} />
        ))}
      </Container>
    </div>
  );
};

BlocksSection.defaultProps = {};

export default BlocksSection;
