/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useWindowSize } from "@utils";
import { motion } from "framer-motion";

const buildUrl = (url, params) =>
  `${url.replace(
    process.env.GATSBY_CDN_BASE_URL,
    process.env.GATSBY_IMGIX_BASE_URL
  )}?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

const Image = ({
  image,
  ixParams,
  objectFit,
  caption,
  transparent,
  alt,
  ratio,
  eager,
  fill,
  aspectRatio,
  widthAdjust: _widthAdjust,
  preserveAspectRatio,
  className,
}) => {
  if (!(image && image?.url)) return null;

  const figure = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [inView, setInView] = useState(false);
  const [loaded, setLoaded] = useState(eager);
  const [widthAdjust, setWidthAdjust] = useState(_widthAdjust);

  const { url, height, width, fpx, fpy } = image || {};
  const pr = typeof window !== "undefined" ? window.devicePixelRatio : 1;
  const arm = aspectRatio ? aspectRatio[1] / aspectRatio[0] : 0;
  const ar = preserveAspectRatio || arm ? arm || height / width : 0;

  const defaultParams = {
    auto: eager ? "compress" : "compress,format",
    fit: "max",
    q: pr > 1.5 ? 70 : 80,
    crop: "faces,edges",
    w: 360, // from state
    h: parseInt(ar ? 360 : 360 * ar, 10), //  from state
    dpr: pr > 2 ? 2 : pr,
  };

  if (fpx && fpy) {
    defaultParams["fp-x"] = fpx;
    defaultParams["fp-y"] = fpy;
    defaultParams.fit = "crop";
    defaultParams.crop = "focalpoint";
  }

  const finalParams = { ...defaultParams, ...ixParams };

  // url states
  const [baseUrl, setBaseUrl] = useState(
    buildUrl(url, {
      ...finalParams,
      w: parseInt(400 * widthAdjust, 10),
      h: parseInt(ar ? ar * 400 * widthAdjust : 400 * widthAdjust, 10),
    })
  );
  const [sources, setSources] = useState(
    [360, 500, 800, 1360, 2000].map(size => ({
      url: buildUrl(url, {
        ...finalParams,
        w: parseInt(size * widthAdjust, 10) || size,
        h:
          parseInt(ar ? ar * size * widthAdjust : size * widthAdjust, 10) ||
          size,
      }),
      size,
    }))
  );
  const [lqip, setLQIP] = useState(
    buildUrl(url, {
      ...finalParams,
      colorquant: 128,
      blur: 200,
      q: 0,
      w: parseInt(200 * widthAdjust, 10) || 200,
      h: parseInt(ar ? ar * 200 * widthAdjust : 200 * widthAdjust, 10) || 200,
    })
  );

  useEffect(() => {
    // load image regardless of inView status after hydration
    setInView(true);
  }, []);

  useEffect(() => {
    if (figure.current) {
      setWidthAdjust(
        (figure.current.clientWidth / windowSize).toFixed(1) || 0.1
      );
    }
  }, [figure.current, windowSize]);

  useEffect(() => {
    setBaseUrl(
      buildUrl(url, {
        ...finalParams,
        dpr: pr > 2 ? 2 : pr,
        w: parseInt(400 * widthAdjust, 10),
        h: parseInt(ar ? ar * 400 * widthAdjust : 400 * widthAdjust, 10),
      })
    );
    setSources(
      [360, 500, 800, 1360, 2000].map(size => ({
        url: buildUrl(url, {
          ...finalParams,
          w: parseInt(size * widthAdjust, 10) || size,
          h:
            parseInt(ar ? ar * size * widthAdjust : size * widthAdjust, 10) ||
            size,
        }),
        size,
      }))
    );
    setLQIP(
      buildUrl(url, {
        ...finalParams,
        colorquant: 128,
        blur: 200,
        q: 0,
        w: parseInt(200 * widthAdjust, 10) || 200,
        h: parseInt(ar ? ar * 200 * widthAdjust : 200 * widthAdjust, 10) || 200,
      })
    );
  }, [widthAdjust, url, pr]);

  return (
    <>
      {eager && (
        <Helmet>
          <link rel="preload" as="image" href={baseUrl} />
        </Helmet>
      )}
      {/* container */}
      <figure
        ref={figure}
        className={classNames(
          className,
          "w-full flex flex-col transition duration-300 overflow-hidden",
          { "absolute inset-0": fill, relative: !fill }
        )}
      >
        {/* the actual image */}
        <div
          className={classNames("z-10", {
            "absolute inset-0": fill,
            relative: !fill,
          })}
          style={{
            paddingTop: 0,
            paddingBottom: fill
              ? 0
              : `${((1 / ratio || height / width) * 100).toFixed(2)}%`,
          }}
        >
          <motion.picture
            initial={eager ? { opacity: 1 } : { opacity: 0 }}
            className={classNames("w-full h-full transition duration-300")}
            animate={loaded ? { opacity: 1 } : { opacity: 0 }}
            // viewport={!eager ? { once: true } : null}
            // onViewportEnter={!eager ? () => setInView(true) : null}
          >
            {/* load srcset */}
            {(inView || eager) && (
              <>
                {sources.map((s, i) => (
                  <source
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    srcSet={s.url}
                    media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                  />
                ))}
              </>
            )}
            <img
              width="100%"
              height="100%"
              onLoad={!eager ? () => setLoaded(true) : null}
              alt={alt || "image"}
              className={classNames(
                "absolute w-full h-full inset-0 object-[50%_33%]",
                {
                  "object-cover": objectFit === "cover",
                  "object-contain": objectFit === "contain",
                }
              )}
              style={
                fpx && fpy
                  ? {
                      objectPosition: `${fpx * 100}% ${fpy * 100}%`,
                    }
                  : null
              }
            />
          </motion.picture>
        </div>
        {/* Low Quality Image Placeholder */}
        {!transparent && (
          <img
            width="100%"
            height="100%"
            alt="placeholder"
            className={classNames(
              "absolute w-full blur-sm h-full inset-0 z-0",
              {
                "object-cover": objectFit === "cover",
                "object-contain": objectFit === "contain",
              }
            )}
            src={lqip}
          />
        )}
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    </>
  );
};

Image.defaultProps = {
  objectFit: "cover",
  eager: false,
  widthAdjust: 1,
};

export default Image;
