import React from "react";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const Feature = ({ copy, title, image, link }) => {
  return (
    <div className="relative px-1">
      <div className="relative z-0 overflow-hidden w-full h-96 shadow-lg group">
        <div className="absolute inset-0 transform transition duration-300 group-hover:scale-105">
          <Image image={image} fill draggable={false} />
        </div>
      </div>
      {/* text box */}
      <div className="-mt-12 px-4">
        <div className="relative z-10 w-full shadow-lg w-full max-w-[24rem] mx-auto bg-white">
          <AppLink to={link.url} className="p-4 md:p-8 block">
            <div className="flex flex-col items-center space-y-4 text-center">
              <Text
                variant="h4"
                className="pb-4 text-blue font-title uppercase underline-gold"
              >
                {title}
              </Text>
              <Text variant="xs" className="text-gray">
                {copy}
              </Text>
            </div>
          </AppLink>
        </div>
      </div>
    </div>
  );
};

Feature.defaultProps = {
  copy: null,
  title: null,
  image: null,
  link: null,
};

export default Feature;
