import React, { useState } from "react";
import { Text, DropDown } from "@atoms";

const AreaMaps = ({ maps }) => {
  const [active, setActive] = useState(0);
  return (
    <div className="my-20">
      <div className="mb-4 max-w-xl">
        <DropDown
          options={maps.map(map => map.title)}
          model={[active, setActive]}
        />
      </div>
      <iframe
        title={maps[active].title}
        className="min-h-1/2-screen w-full"
        src={maps[active].src}
      />
      <Text variant="sm" className="text-gray mt-4">
        {maps[active].caption}
      </Text>
    </div>
  );
};

AreaMaps.defaultProps = {};

export default AreaMaps;
