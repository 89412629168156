import React from "react";
import {
  CbCopy,
  CbCompanies,
  CbContactCard,
  CbCta,
  CbImage,
  CbLinks,
  CbPullQuote,
  CbVideo,
  CbEmbedCode,
  CbPdfs,
} from "@molecules";

const ContentBuilder = ({ blocks }) => {
  return (
    <div className="content-builder">
      {blocks &&
        blocks.map(block => {
          if (block) {
            switch (block.type) {
              case "cbCta":
                return <CbCta {...block} />;
              case "cbParagraph":
                return <CbCopy {...block} />;
              case "cbCompanies":
                return <CbCompanies {...block} />;
              case "cbPullQuote":
                return <CbPullQuote {...block} />;
              case "cbImage":
                return <CbImage {...block} />;
              case "cbVideo":
                return <CbVideo {...block} />;
              case "cbLinks":
                return <CbLinks {...block} />;
              case "cbEmbed":
                return <CbEmbedCode {...block} />;
              case "cbPdfs":
                return <CbPdfs {...block} />;
              case "cbContactCard":
                return <CbContactCard {...block} />;
              default:
                return null;
            }
          }
          return null;
        })}
    </div>
  );
};

export default ContentBuilder;
