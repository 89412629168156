import React from "react";
import { Text, CbWrapper, Image } from "@atoms";
import { AppLink } from "@base";

const CbCta = ({ type, image, title, subtitle, url }) => {
  return (
    <CbWrapper type={type}>
      <AppLink to={url}>
        <div className="flex justify-center items-center relative z-10 p-4 md:p-16">
          <div className="max-w-md w-full p-4 border-gold border-2 flex flex-col space-y-4 text-center">
            <Text variant="title" className="text-gold">
              {subtitle}
            </Text>
            <Text variant="h3" className="uppercase text-white">
              {title}
            </Text>
          </div>
        </div>
        <div className="absolute inset-0 z-0">
          <Image image={image} fill ixParams={{ exp: "-5", con: "-5" }} />
        </div>
      </AppLink>
    </CbWrapper>
  );
};

CbCta.defaultProps = {};

export default CbCta;
