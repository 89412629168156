import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Text, Image } from "@atoms";

const ImageCard = ({ full, image, subtitle, title, description, url }) => {
  return (
    <div
      className={classNames(
        "col-span-1 relative flex justify-center items-center h-auto md:h-96 overflow-hidden",
        {
          "md:col-span-2": full,
        }
      )}
    >
      <div className="absolute w-full h-full z-10 bg-blue overflow-hidden">
        <Image
          image={image}
          fill
          ixParams={{ exp: "-10", con: "-5", sat: "-10" }}
        />
      </div>
      <AppLink
        to={url}
        className={classNames(
          "group relative items-center p-6 m-6 border-gold border-2 w-full max-w-sm text-center text-white z-20 overflow-hidden",
          {
            "animate-height": !full,
          }
        )}
      >
        <Text variant="h4" className="text-gold uppercase">
          {subtitle}
        </Text>
        <Text variant="h3" className="uppercase mt-4">
          {title}
        </Text>
        <div
          className={classNames("", {
            "md:opacity-0 md:group-hover:opacity-100": !full,
          })}
        >
          <div className="inline-block h-[2px] w-20 bg-gold my-4" />
          <Text variant="xs" className="">
            {description}
          </Text>
        </div>
      </AppLink>
    </div>
  );
};

ImageCard.defaultProps = {};

export default ImageCard;
