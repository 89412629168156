import React from "react";
import { StatBlock } from "@organisms";
import { StatBgBlock } from "@molecules";

const StatsSection = ({ blocks }) => {
  return (
    <section className="relative">
      {blocks &&
        blocks.map(block => {
          if (block) {
            switch (block.type) {
              case "dataImageBgBlock":
                return <StatBgBlock {...block} />;
              case "dataWhiteBgBlock":
                return <StatBlock {...block} />;
              default:
                return <StatBlock {...block} />;
            }
          }
          return null;
        })}
    </section>
  );
};

StatsSection.defaultProps = {};

export default StatsSection;
