import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import classNames from "classnames";
import { Text, Icon } from "@atoms";
// import { uid } from "react-uid";

const Dropdown = ({ model, options, placeholder, inverse, size }) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = model;
  const dropdown = useRef();
  const dropdownControl = useAnimation();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // animations
  useEffect(() => {
    if (isActive) {
      dropdownControl.start({
        pointerEvents: "auto",
        height: "auto",
        opacity: 1,
        display: "block",
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      dropdownControl.start({
        pointerEvents: "none",
        height: 0,
        opacity: 0,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
        transitionEnd: {
          display: "hidden",
        },
      });
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  return (
    <div className="cursor-pointer flex relative z-50" ref={dropdown}>
      <motion.button
        type="button"
        onTap={() => setIsActive(!isActive)}
        className="flex w-full"
      >
        <span className="flex items-center relative flex-1 bg-gray-light px-2 border-2 border-solid border-blue">
          <Text
            variant="xs"
            className="font-title text-blue uppercase truncate"
          >
            {value != null && value !== "" ? options[value] : placeholder}
          </Text>
        </span>
        {/* dropdown Icon */}
        <div className="flex items-center px-2 bg-gray-light border-2 border-blue border-l-0">
          <Icon
            name="dropdown"
            className={classNames("text-gold w-5 transform duration-300", {
              "rotate-180": isActive,
            })}
          />
        </div>
      </motion.button>
      <motion.ul
        animate={dropdownControl}
        className="absolute top-full bg-white w-full border-2 border-solid border-blue z-40 -mt-0.5 opacity-0 hidden"
      >
        {placeholder.length > 1 && (
          <li>
            <button
              type="button"
              className="w-full px-4 py-2 text-xs opacity-50 font-title uppercase text-left"
              disabled
            >
              {placeholder}
            </button>
          </li>
        )}
        {options.map((option, i) => {
          return (
            <li
              className="py-2 px-4"
              key={option.uid ? option.uid : option + String(i)}
            >
              <motion.button
                type="button"
                className="w-full text-left"
                onTap={() => setValue(i)}
              >
                <Text
                  variant="xs"
                  className="text-left inline-block border-0 border-b-2 border-solid border-transparent transition font-title text-blue uppercase leading-loose hover:text-blue hover:border-gold"
                >
                  {option}
                </Text>
              </motion.button>
            </li>
          );
        })}
      </motion.ul>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
};

export default Dropdown;
