import React from "react";
import { CbWrapper } from "@atoms";

const EmbedCode = ({ type, code: _code }) => {
  // standardize urls + strip duplicitous code
  const code = _code
    .replace(`<script async src="//www.instagram.com/embed.js"></script>`, "")
    .replace(
      `<script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
      ""
    )
    .replace("https://", "//")
    .replace("http://", "//")
    .replace("//", "https://");
  if (typeof window !== "undefined") {
    return (
      <CbWrapper type={type}>
        <div className="embedCode">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: code }} />
        </div>
      </CbWrapper>
    );
  }
  return <div />;
};

export default EmbedCode;
