import React from "react";
import { Heading } from "@molecules";
import { Container, Stat, Button } from "@atoms";

const DataOverview = ({ heading, copy, stats, link }) => {
  return (
    <Container variant="xl" className="pt-16 md:pt-24 pb-8">
      <Heading heading={heading} copy={copy} />
      {stats && (
        <div className="flex flex-wrap mt-8">
          {stats.map(stat => (
            <Stat key={stat.id} {...stat} count={stats.length} inverse />
          ))}
        </div>
      )}
      <div className="flex justify-center mt-8">
        {link?.url && <Button to={link.url}>Learn More</Button>}
      </div>
    </Container>
  );
};

DataOverview.defaultProps = {};

export default DataOverview;
