import React from "react";
import { HighlightText } from "@molecules";
import { Text, Container, Button, Image } from "@atoms";

const HeroGrid = ({ heading, subhead, images, button }) => {
  return (
    <section className="relative">
      {/* background images */}
      <div className="absolute flex flex-wrap inset-0 z-10 mt-16 md:mt-20">
        {images && (
          <>
            <div className="relative w-full h-1/2 lg:h-full lg:w-1/2 border-0 lg:border-r-2 border-white border-solid overflow-hidden">
              <Image eager image={images[0]} fill ixParams={{ exp: "-10" }} />
            </div>
            <div className="flex w-full h-1/2 lg:h-full lg:w-1/2 flex-row lg:flex-col">
              <div className="h-full w-1/2 lg:w-full lg:h-1/2 border-black border-0 border-t-2 border-r-2 lg:border-0 lg:border-b-2 lg:border-white border-solid relative overflow-hidden">
                <Image eager image={images[1]} fill />
              </div>
              <div className="h-full w-1/2 lg:w-full lg:h-1/2 border-black border-0 border-t-2 border-solid lg:border-0 relative overflow-hidden">
                <Image eager image={images[2]} fill />
              </div>
            </div>
          </>
        )}
      </div>

      <Container variant="xl" className="relative h-auto pt-16 md:pt-20">
        <div className="relative flex flex-col h-full justify-center z-30 w-full lg:w-1/2 pr-6 md:pr-12 lg:pr-28 py-12 sm:py-16 md:py-28 lg:py-32 xl:py-40">
          <div className="max-w-md md:max-w-sm text-shadow-md break-words whitespace-pre-line">
            <HighlightText variant="h1" className="text-gold" fill>
              {heading}
            </HighlightText>
            <Text className="block texl-lg sm:text-xl leading-normal text-gray-light text-shadow-md mt-8">
              {subhead}
            </Text>
            <div className="mt-8">
              <Button color="white" to={button.url}>
                {button.text}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

HeroGrid.defaultProps = {};

export default HeroGrid;
