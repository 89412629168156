import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";
import resolveImage from "../dataResolvers/resolveImage";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

// const previewQuery = `
//   query DefaultsQuery {
//     craft {
//       globalSet(handle: "defaults") {
//         ... on Craft_defaults_GlobalSet {
//           metaTitle
//           metaDescription
//           metaImage {
//             width
//             height
//             hasFocalPoint
//             slug
//             status
//             title
//             focalPoint
//             url
//           }
//         }
//       }
//     }
//   }
// `;

const dataResolver = ({ craft }) => {
  const { metaTitle, metaDescription, metaImage } = craft.globalSet;
  return {
    meta: {
      siteTitle: "San Bernardino",
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
