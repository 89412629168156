import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query MapQuery {
    craft {
      entry(section: "map") {
        ... on Craft_map_map_Entry {
          title
          mapData {
            url
          }
          maptilerApiKey
          districtShapefile {
            url
          }
        }
      }
    }
  }
`;

// const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { title, mapData, maptilerApiKey, districtShapefile } = craft.entry;
  return {
    title,
    data: mapData[0]?.url,
    key: maptilerApiKey,
    districts: districtShapefile[0]?.url,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
