import React, { useState } from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import {
  Heading,
  ArticlePreview,
  Pagination,
  CategoriesDropdown,
} from "@molecules";

const BlogPreview = ({
  heading,
  copy,
  articleSubheading,
  articles,
  color,
  small,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePosts, setVisiblePosts] = useState(9);

  // Pagination
  const paginate = (array, page = currentPage, perPage = visiblePosts) => {
    const grabbedPage = page === 1 ? 0 : (page - 1) * perPage;
    const offset = page === 1 ? perPage : page * perPage;
    return array.slice(grabbedPage, offset);
  };

  return (
    <section
      className={classNames("py-16", {
        "bg-white": color === "white",
        "bg-gray-light": color === "gray",
      })}
    >
      <Container variant="xl">
        {heading && <Heading heading={heading} copy={copy} small={small} />}
        {articleSubheading && (
          <div className="flex flex-wrap justify-between">
            <Text variant="xxl" className="inline-block font-bold">
              <span className="text-blue-dark">
                Articles: <span className="text-gold">{articleSubheading}</span>
              </span>
            </Text>
            <CategoriesDropdown />
          </div>
        )}
        <ul
          className={classNames(
            "grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-12",
            {
              "mt-16": heading,
              "mt-0 md:mt-8": !heading,
            }
          )}
        >
          {paginate(articles)?.map(article => (
            <ArticlePreview {...article} />
          ))}
        </ul>
        <Pagination
          articleAmount={articles?.length}
          pageState={[currentPage, setCurrentPage]}
          visibleState={[visiblePosts, setVisiblePosts]}
        />
      </Container>
    </section>
  );
};

BlogPreview.defaultProps = {
  color: "white",
};

export default BlogPreview;
