import resolveImage from "./resolveImage";

const resolveTeam = entries =>
  entries
    .filter(t => t.teamMember?.[0]?.enabled)
    .map(t => {
      return {
        uid: t.teamMember[0].uid,
        name: t.teamMember[0].fullName,
        image: resolveImage(t.teamMember[0].image),
        linkedIn: t.teamMember[0].contactInfo?.[0].linkedin,
        title: t.teamMember[0].position,
        email: t.teamMember[0].email,
        bio: t.teamMember[0].bio,
      };
    });

export default resolveTeam;
