import React from "react";
import { Container, Image, Text, Stat } from "@atoms";

const StatBgBlock = ({ image, heading, stats }) => {
  return (
    <div className="relative pt-24 md:pt-32 pb-24 md:pb-36">
      <div className="absolute inset-0 z-10">
        <Image image={image} fill ixParams={{ exp: "-10", con: "-10" }} />
      </div>
      <Container variant="xl" className="relative z-20">
        <div className="mx-0 sm:mx-20 md:mx-40 pb-4 border-b-2 border-gold">
          <Text
            variant="h2--sans"
            className="block text-white text-center font-bold"
          >
            {heading}
          </Text>
        </div>
        {stats && (
          <div className="flex flex-wrap justify-center mt-12">
            {stats.map((s, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stat {...s} light count={stats.length} key={i} />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

StatBgBlock.defaultProps = {};

export default StatBgBlock;
