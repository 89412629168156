import React, { useState } from "react";
import classNames from "classnames";
import { Heading, IconCard, PersonCard } from "@molecules";
import { Container } from "@atoms";

const CardGrid = ({
  heading,
  copy,
  icons,
  cards,
  showContact,
  className: _className,
  horizontal,
  activeSlide,
  openModal,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentSlide, setCurrentSlide] = activeSlide || useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = openModal || useState(false);
  return (
    <div className={classNames("py-16 md:py-24", _className)}>
      <Container variant="xl">
        {heading && <Heading heading={heading} copy={copy} />}
        <div
          className={classNames("grid gap-6 md:gap-12", {
            "mt-16 md:mt-24": heading,
            "lg:grid-cols-3": icons || !horizontal,
            "grid-cols-1 md:grid-cols-2": icons || horizontal,
            "grid-cols-1 sm:grid-cols-2": !icons && !horizontal,
          })}
        >
          {/* eslint-disable-next-line react/no-array-index-key */}
          {icons && cards?.map((card, i) => <IconCard key={i} {...card} />)}
          {showContact && (
            <IconCard
              heading="Meet With Us"
              copy="See why international companies like Amazon, Fed Ex, Walmart, and UPS have long-established hubs in San Bernardino County."
              link={{ text: "Schedule", url: "/contact" }}
            />
          )}
          {!icons &&
            cards?.map((card, i) => (
              <PersonCard
                key={card.uid}
                horizontal={horizontal}
                {...card}
                openModal={[open, setOpen]}
                activeSlide={[currentSlide, setCurrentSlide]}
                index={i}
              />
            ))}
        </div>
      </Container>
    </div>
  );
};

CardGrid.defaultProps = {
  icons: true,
};

export default CardGrid;
