import React from "react";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const EventPreview = ({ date, title, time, url }) => {
  return (
    <div className="flex flex-wrap items-center p-8 bg-white border-t-3 border-gold space-y-4 md:space-y-0 md:space-x-4">
      <Text variant="lg" className="font-bold text-gold md:flex-1">
        {date}
      </Text>
      <AppLink
        to={url}
        className="text-left text-blue uppercase w-full md:w-1/2 hover:text-gold transition duration-300"
      >
        <Text variant="h4" className="inline-block">
          {title}
        </Text>
      </AppLink>
      <div className="text-gray text-xs md:text-center w-full md:flex-1 space-x-1">
        <span className="font-bold">Time:</span>
        <span>{time}</span>
      </div>
      <AppLink to={url} className="flex items-center group">
        <span className="text-xs text-blue">View Event</span>
        <Icon
          name="arrow"
          className="ml-2 w-4 h-4 text-gold transform group-hover:translate-x-2 transition duration-300"
        />
      </AppLink>
    </div>
  );
};

EventPreview.defaultProps = {};

export default EventPreview;
