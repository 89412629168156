import React from "react";
import classNames from "classnames";
import { Text, Image } from "@atoms";

// may need to rewrite to slice first number from string rather than using two different variables
const Stat = ({ icon, value, label, inverse, light, count }) => {
  return (
    <div
      className={classNames("flex flex-col items-center text-center p-6", {
        "w-3/4": !count,
        "w-1/2": count,
        "md:w-1/3": count === 3,
        "md:w-1/4": count === 4,
        "md:w-1/5": count === 5,
      })}
    >
      {/* {icon && 
        <div className="flex items-end w-12 md:w-16 h-12 md:h-16 mb-4 md:mb-6">
          <Icon name={icon} className={classNames("w-full", {
            "text-white": !inverse,
            "text-blue": inverse,
          })} />
        </div>
      } */}
      {icon && (
        <div className="flex items-end h-12 md:h-16 w-12 md:w-16 mb-4 md:mb-6 relative">
          <Image image={icon} objectFit="contain" fill transparent />
        </div>
      )}
      <Text
        className={classNames(
          "block font-title text-2xl md:text-4xl uppercase",
          {
            "text-gray": !icon && !inverse && !light,
            "text-gold": (icon && !inverse) || light,
            "text-blue": inverse,
          }
        )}
      >
        {value}
      </Text>
      <Text
        className={classNames(
          "text-base md:text-lg font-title uppercase block mt-2",
          {
            "text-blue": !icon && !inverse && !light,
            "text-white": (icon && !inverse) || light,
            "text-gray": inverse,
          }
        )}
      >
        {label}
      </Text>
    </div>
  );
};

Stat.defaultProps = {
  inverse: false,
  light: false,
  count: null,
};

export default Stat;
