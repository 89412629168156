import React from "react";
import classNames from "classnames";
import { CbWrapper, Video, Text } from "@atoms";

const CbVideo = ({ id, provider, type, subheading, heading, description }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-wrap -mx-6">
        {(subheading || heading || description) && (
          <div className="w-full lg:w-1/2 px-6 mb-6 lg:mb-0">
            <Text variant="h4--sans" className="uppercase text-gold font-bold">
              {subheading}
            </Text>
            <Text variant="h2--sans" className="text-blue font-bold mt-4">
              {heading}
            </Text>
            <Text variant="body" className="text-gray mt-6">
              {description}
            </Text>
          </div>
        )}
        <div
          className={classNames("w-full px-6", {
            "lg:w-1/2": subheading || heading || description,
          })}
        >
          <Video id={id} provider={provider} />
        </div>
      </div>
    </CbWrapper>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
