import React from "react";
import { formatDate } from "@utils";
import { AppLink } from "@base";
import { Text, Image, Button } from "@atoms";

const FeaturedArticle = ({ url, title, date, categories, copy, image }) => {
  return (
    <div className="flex flex-wrap border-t-3 border-transparent box-border border-gold p-8 shadow-lg bg-white mt-8">
      <div className="flex flex-col w-full sm:w-2/3 order-2 sm:order-1 mt-4 sm:mt-0 mr-0 sm:pr-8">
        <AppLink to={url} className="group block">
          <Text className="leading-relaxed text-blue hover:underline-gold transition duration-300">
            {title}
          </Text>
        </AppLink>
        <div className="sm:flex mt-4 mb-8">
          <Text variant="xxs" className="text-gray">
            {formatDate(date, "MMMM dd, yyyy")}
          </Text>
          <Text variant="xxs" className="text-gray ml-0 sm:ml-4">
            {`Category: ${categories}`}
          </Text>
        </div>
        {copy && (
          <Text variant="xs" className="hidden sm:block md:mb-8">
            {copy}
          </Text>
        )}
        <div className="mt-auto">
          <Button to={url}>Read More</Button>
        </div>
      </div>
      <div className="w-full sm:w-1/3 order-1 sm:order-2">
        <AppLink to={url}>
          <div className="relative pb-[75%] overflow-hidden bg-cover bg-center">
            <div className="absolute inset-0">
              <Image image={image} fill />
            </div>
          </div>
        </AppLink>
      </div>
    </div>
  );
};

export default FeaturedArticle;
