/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Suspense, useRef, useEffect } from "react";
import "../../pcss/video.pcss";
import Image from "./Image";

const Video = ({ id, muted, autoplay, placeholder, play, provider }) => {
  let url;
  const plyr = useRef();
  const player = useRef();
  const options = {
    muted,
    autoplay,
  };

  if (provider === "youtube") {
    if (id) {
      url = `https://youtu.be/${id}`;
    } else {
      return null;
    }
  }

  if (provider === "vimeo") {
    if (id) {
      url = `https://vimeo.com/${id}`;
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (player.current) {
      if (play) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [play]);

  if (typeof window !== "undefined" && url) {
    // eslint-disable-next-line global-require
    const Plyr = require("plyr");
    useEffect(() => {
      setTimeout(() => {
        if (!player.current) {
          if (plyr.current && Plyr) {
            player.current = new Plyr(plyr.current);
            if (!play) {
              player.current.pause();
            }
          }
        }
      }, 500);
    }, []);
    // set up the player
    return (
      <Suspense
        fallback={
          <div>
            <Image image={placeholder} />
          </div>
        }
      >
        <div
          data-plyr-provider={provider}
          data-plyr-embed-id={url}
          ref={plyr}
          data-plyr-config={JSON.stringify(options)}
        />
      </Suspense>
    );
  }
  return (
    <div>
      <Image image={placeholder} />
    </div>
  );
};

Video.defaultProps = {
  play: false,
};

export default Video;
