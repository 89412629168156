import React from "react";
import { Image } from "@atoms";
import { AppLink } from "@base";

const InstagramPost = ({ url, image }) => {
  return (
    <AppLink
      to={url}
      className="block absolute w-full h-full overflow-hidden border-3 border-white transition duration-300 hover:opacity-50"
    >
      <div className="w-[130%] absolute inset-0 transform -skew-x-[15deg]">
        <div className="absolute inset-0 -ml-[15%]">
          <Image image={image} fill />
        </div>
      </div>
    </AppLink>
  );
};

InstagramPost.defaultProps = {};

export default InstagramPost;
