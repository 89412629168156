import React from "react";
import { HighlightText } from "@molecules";
import { Text, CbWrapper } from "@atoms";

const CbHeading = ({ heading, copy, type }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-wrap md:flex-nowrap text-center md:text-left items-center">
        <div className="w-full max-w-sm break-words whitespace-pre-line mx-auto md:mx-0 md:pr-6">
          <HighlightText variant="h2">{heading}</HighlightText>
        </div>
        {copy && (
          <div className="max-w-lg mt-6 mx-12 md:m-0 p-0 md:pl-4 md:py-2 border-blue md:border-l-2 text-gray">
            <Text variant="lg">{copy}</Text>
          </div>
        )}
      </div>
    </CbWrapper>
  );
};

CbHeading.defaultProps = {};

export default CbHeading;
