/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import classNames from "classnames";
import { Text, Button, Image } from "@atoms";

const ContactForm = ({ heading, copy, image, centered }) => {
  return (
    <div
      className={classNames({
        "w-full md:w-3/5 px-6": image,
        "max-w-xl": !image,
        "mx-auto": centered,
      })}
    >
      <div className={classNames("shadow-lg p-8 md:p-12 bg-white", {})}>
        {heading && !image && (
          <Text variant="xxl" className="text-blue font-bold mb-10">
            {heading}
          </Text>
        )}
        {image && (
          <div className="flex items-center mb-16">
            <div className="w-16 h-16 rounded-full bg-blue flex-shrink-0">
              <Image image={image} round />
            </div>
            <div className="ml-4">
              <Text className="text-base leading-normal font-bold text-gold">
                {heading}
              </Text>
              <Text variant="xs--tight" className="text-gray mt-2">
                {copy}
              </Text>
            </div>
          </div>
        )}
        <div className="relative transition duration-300">
          <form id="contact-form" name="Contact Form">
            <input type="hidden" name="action" />
            <div className="sm:flex mb-4">
              <div className="flex-1 sm:pr-4 sm:w-1/2 sm:mb-0 mb-4">
                <label htmlFor="first_name" className="block mb-2">
                  <Text variant="label" className="text-gray">
                    First name
                  </Text>
                </label>
                <div className="sm:flex">
                  <input
                    type="text"
                    name="first_name"
                    required="required"
                    placeholder="Required field"
                    className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
                  />
                </div>
              </div>
              <div className="flex-1 sm:pl-4 sm:w-1/2 sm:mb-0">
                <label htmlFor="last_name" className="block mb-2">
                  <Text variant="label" className="text-gray">
                    Last name
                  </Text>
                </label>{" "}
                <div className="sm:flex">
                  <input
                    type="text"
                    name="last_name"
                    required="required"
                    placeholder="Required field"
                    className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
                  />
                </div>
              </div>
            </div>
            <label htmlFor="email" className="block mb-2">
              <Text variant="label" className="text-gray">
                Email
              </Text>
            </label>
            <div className="sm:flex mb-4">
              <input
                type="email"
                id="email"
                name="email"
                required="required"
                placeholder="Required field"
                className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
              />
            </div>
            <div className="mb-6 sm:mb-8">
              <label htmlFor="description" className="block mb-2">
                <Text variant="label" className="text-gray">
                  Message
                </Text>
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className="block bg-gray-light p-2 w-full text-sm leading-normal placeholder-gray"
              />
            </div>
            <div className="flex justify-center">
              <Button size="sm">Submit</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ContactForm.defaultProps = {};

export default ContactForm;
