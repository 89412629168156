/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Newsletter, InstagramFeed, Footer } from "@organisms";
import { ContactSection } from "@molecules";
import { Meta } from "@atoms";
import { useAppState } from "@state/state";

const PageContainer = ({ children, className, path, meta, contact, hide }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();

  const pageVariants = {
    enter: {
      opacity: 1,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.75,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.75,
      },
    },
  };

  return (
    <motion.div
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={pageVariants}
      className={classNames(
        "relative z-0 flex flex-col flex-grow min-h-screen bg-white",
        className
      )}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
    >
      <main className={classNames(className, "flex flex-grow flex-col")}>
        {children}
      </main>
      <Newsletter />
      {!contact && <ContactSection />}
      <InstagramFeed />
      <Footer />
      {meta && <Meta {...meta} />}
    </motion.div>
  );
};

export default PageContainer;
