import React from "react";
import { Text, Button, Icon } from "@atoms";
import { AppLink } from "@base";

const UpcomingEvents = ({ heading, events }) => {
  return (
    <div className="shadow-lg p-6 bg-white">
      <Text variant="xxl" className="text-blue font-bold">
        {heading}
      </Text>
      {events && (
        <ul className="flex flex-col list-none mt-4 space-y-4">
          {events.map(event => (
            <li className="relative pl-6">
              <div className="absolute top-0 left-0 w-3 h-3">
                <Icon name="calendar" className="relative text-gold" />
              </div>
              <Text className="text-gray text-xs leading-tight">
                {event.date}
              </Text>
              <AppLink to={event.url} className="group block mt-1">
                <Text className="text-sm leading-relaxed text-blue hover:underline-gold transition duration-300">
                  {event.title}
                </Text>
              </AppLink>
            </li>
          ))}
        </ul>
      )}
      {events.length < 1 && (
        <Text className="block text-gray text-xs leading-tight">
          There are no events at this time.
        </Text>
      )}
      <Button to="/events" size="sm" color="gray" className="mt-6">
        Full Calendar
      </Button>
    </div>
  );
};

export default UpcomingEvents;
