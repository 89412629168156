import React from "react";
import { Text, CbWrapper } from "@atoms";

const CbCopy = ({ copy, type }) => {
  return (
    <CbWrapper type={type}>
      <Text variant="body--xsmall">{copy}</Text>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
