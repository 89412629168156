import React from "react";
import classNames from "classnames";
import { Text, Button, Image } from "@atoms";

// displays image on right
const ImageCopyBlock = ({
  heading,
  copy,
  pdf,
  link,
  icon,
  image,
  large,
  flip,
}) => {
  if (pdf) {
    return (
      <div className="bg-white flex flex-col mt-12 shadow-lg">
        <div className="relative block w-full h-48 md:h-64 lg:h-96">
          <Image image={image} fill />
        </div>
        <div className="w-full p-8 bg-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
            <div>
              <Text variant="title" className="text-blue">
                {heading}
              </Text>
              <Text variant="xs" className="text-gray mt-4">
                {copy}
              </Text>
            </div>
            <div className="flex flex-col max-w-[7rem]">
              <div className="relative w-full h-36 overflow-hidden">
                <Image image={pdf} ixParams={{ page: 1 }} fill />
              </div>
              <Button to={pdf.url} size="fluid" className="mt-4">
                View PDF
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "relative flex w-full min-h-[18rem] flex-wrap-reverse",
        {
          "mt-16 md:mt-24": large,
          "border-1 border-gray-light bg-white shadow-lg mt-6 md:mt-12": !large,
          "flex-row md:flex-row-reverse": flip,
          "flex-row-reverse md:flex-row": !flip,
        }
      )}
    >
      <div
        className={classNames("flex flex-col w-full md:w-1/2", {
          "py-4": large,
          "p-8": !large,
          "md:pr-12": large && !flip,
          "md:pl-12": large && flip,
        })}
      >
        {icon && (
          <div className="hidden md:block w-20 h-full">
            <Image
              image={icon}
              className="w-full"
              objectFit="contain"
              transparent
            />
          </div>
        )}
        <Text
          variant={large && !icon ? "h3--sans" : "h4"}
          className={classNames("text-blue", {
            "mt-4 md:mt-8": icon,
            uppercase: !large || icon,
            "font-bold mt-6 md:mt-0": large && !icon,
          })}
        >
          {heading}
        </Text>
        <Text
          variant={large && !icon ? "body" : "xs"}
          className="text-gray mt-6 column-list"
        >
          {copy}
        </Text>
        {link?.url && (
          <div className="flex justify-end mt-auto">
            <Button to={link.url} size="sm" className="mt-4">
              {link.text || "Read More"}
            </Button>
          </div>
        )}
      </div>

      <div
        className={classNames(
          "relative w-full md:w-1/2 h-48 sm:h-64 md:h-auto",
          {
            "md:shadow-lg": large,
          }
        )}
      >
        <div
          className={classNames("absolute inset-0 overflow-hidden", {
            "shadow-lg": large,
          })}
        >
          <Image image={image} fill position="center" />
        </div>
      </div>
    </div>
  );
};

ImageCopyBlock.defaultProps = {};

export default ImageCopyBlock;
