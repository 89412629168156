import React from "react";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { useHeaderData } from "@staticQueries";
import { useAppState } from "@state/state";
import { MobileHeader } from "@molecules";

const Header = () => {
  const [{ layout }, dispatch] = useAppState();
  const { hideNav } = layout;
  const { nav, contactCounty } = useHeaderData();

  return (
    <>
      <div className="lg:hidden h-8 inset-0 bg-blue px-8 flex justify-end items-center">
        <a
          href={contactCounty.url}
          target="_blank"
          rel="noreferrer"
          className="-mt-1 leading-none font-body group block lg:inline centered text-white lg:text-blue text-sm hover:text-gold transition"
        >
          <Text className="text-[0.7rem] font-bold text-center text-white group-hover:text-gold transition duration-300">
            {contactCounty.mobile}
          </Text>
          <Icon
            name="externalLink"
            className="ml-0.5 w-2 h-2 text-white group-hover:text-gold transition duration-300"
          />
        </a>
      </div>
      <header className="h-16 md:h-20 w-full top-0 absolute z-50 top-8 lg:top-0 overflow-visible">
        <div className="h-full flex items-center justify-between px-4 md:px-6 py-2 absolute top-0 z-50 h-auto w-full">
          <AppLink
            to="/"
            className="w-full max-w-[12rem] lg:w-52 flex-shrink-0 relative inline-block mr-8"
            title="San Bernardino County home"
            onClick={() => {
              dispatch({
                type: "hideNav",
              });
            }}
          >
            <Icon name="logo" className="w-full" />
          </AppLink>

          <nav className="flex">
            <ul className="hidden lg:flex items-center space-x-9">
              {nav.map(item => {
                const link = item?.link;
                const { url, text } = link;
                const { children } = item;

                return (
                  <li
                    className="relative group block inline-flex text-center items-center justify-center py-4"
                    key={item.id}
                  >
                    <span className="relative z-20">
                      <AppLink
                        to={url}
                        className={classNames(
                          "font-title uppercase text-xs text-blue inline-flex items-center",
                          {
                            "transition duration-300 border-b-3 border-transparent hover:border-gold focus:border-gold":
                              !children?.length,
                          }
                        )}
                        activeClassName=""
                      >
                        {text}
                        {children?.length > 0 && (
                          <Icon
                            name="dropdown"
                            className="text-gold w-5 transform duration-300 group-hover:rotate-180"
                          />
                        )}
                      </AppLink>
                    </span>
                    {children?.length > 0 && (
                      <ul className="transition duration-300 opacity-0 invisible group-hover:visible group-hover:opacity-100 absolute w-max bg-white z-10 p-6 space-y-6 w-max min-w-48 border-t-3 border-gold top-full bg-white">
                        {children?.map(subItem => {
                          return (
                            <li key={subItem.id}>
                              <AppLink
                                to={subItem?.link?.url}
                                className="font-title uppercase text-gray hover:underline-gold focus:underline-gold hover:text-blue text-xs transition duration-300"
                              >
                                {subItem?.link?.text}
                              </AppLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}

              {contactCounty?.url && (
                <li className="relative group block inline-flex text-center items-center justify-center py-4">
                  <span className="relative z-20">
                    <AppLink
                      to={contactCounty?.url}
                      className="font-title uppercase text-xs text-blue inline-flex items-center transition duration-300 border-b-3 border-transparent hover:border-gold focus:border-gold"
                      activeClassName=""
                    >
                      {contactCounty?.desktop}
                    </AppLink>
                  </span>
                </li>
              )}

              <li className="relative block inline-flex text-center py-4">
                <AppLink
                  onClick={() => {
                    dispatch({
                      type: "showSearch",
                    });
                  }}
                >
                  <Icon
                    name="search"
                    className="w-3 h-3 text-blue hover:text-gold transition duration-300"
                  />
                </AppLink>
              </li>
            </ul>

            <button
              type="button"
              className="block lg:hidden"
              onClick={() => {
                dispatch({
                  type: !hideNav ? "hideNav" : "showNav",
                });
              }}
            >
              {/* <Icon name="bars" className="w-8 h-8 text-blue" /> */}
              <div className={classNames("hamburger", { "is-open": !hideNav })}>
                <div className="hamburger__filling" />
              </div>
            </button>
          </nav>
        </div>
        {/* Mobile navigation */}
        <MobileHeader nav={nav} />
      </header>
    </>
  );
};

export default Header;
