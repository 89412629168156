import React from "react";
import { AppLink } from "@base";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  className: _className,
}) => {
  let classes = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 uppercase font-title tracking-wide group leading-tighter transition duration-300 border-2 hover:border-gold group-hover:border-gold hover:text-gold`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-3 text-sm";
      break;
    case "xxs":
      classes += " p-2 text-xxs";
      break;
    case "xs":
      classes += " px-4 py-2 text-xxs";
      break;
    case "sm":
      classes += " px-6 py-3 text-xs";
      break;
    case "md":
      classes += " px-6 py-3 text-sm";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    default:
      classes += " px-8 py-3 text-sm";
      break;
  }

  switch (color) {
    case "white":
      classes += " border-white text-white";
      break;
    case "blue":
      classes += " border-gray text-blue";
      break;
    default:
      classes += " border-gray text-gray";
      break;
  }

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={`${classes} ${_className}`}
        onClick={onClick}
      >
        <span className="flex relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${classes} ${_className}`}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

export default Button;
