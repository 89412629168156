import React from "react";
import { ColorBlock } from "@molecules";
import { Container } from "@atoms";
import { usePreFooterData } from "@staticQueries";

const ContactSection = () => {
  const { contact } = usePreFooterData();
  if (contact) {
    return (
      <section className="pt-8 pb-16 md:pt-16 md:pb-24 bg-gray-light">
        <Container variant="xl">
          <ColorBlock {...contact} />
        </Container>
      </section>
    );
  }
  return null;
};

export default ContactSection;
