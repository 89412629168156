import React, { useState, useEffect } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { Container, Text, Icon, Image, Button } from "@atoms";
import { AppLink } from "@base";
import { Carousel, PersonCard } from "@molecules";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const TeamModal = ({ team, activeSlide, openModal }) => {
  const [currentSlide, setCurrentSlide] = activeSlide || useState(0);
  const [open, setOpen] = openModal || useState(false);

  useEffect(() => {
    if (open) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="relative z-50"
        >
          <div
            className="bg-black bg-opacity-50 fixed inset-0 w-full h-screen z-50"
            onClick={() => setOpen(false)}
          />
          <div className="flex items-center justify-center w-full z-[100] fixed top-0 md:top-1/2 translate-y-0 md:-translate-y-1/2">
            <div className="bg-white w-full h-screen md:h-auto md:max-h-[200rem] p-0 md:p-8 shadow-lg overflow-y-auto">
              <div className="w-full max-w-3xl xl:max-w-4xl mx-auto overflow-x-hidden">
                <div className="fixed z-50 md:relative w-full top-0 flex pt-6 md:pt-0 pb-6 divide-x-2 divide-gray-light bg-white">
                  <span className="ml-auto md:ml-0">
                    <button
                      type="button"
                      className="border-2 border-r-0 border-solid border-gray-light relative inline-flex justify-center items-center h-10 px-3 cursor-pointer bg-transparent flex-row  active:opacity-75 group transition text-light"
                      onClick={() => {
                        setCurrentSlide(prevState => {
                          if (prevState > 0) {
                            return prevState - 1;
                          }
                          return prevState;
                        });
                      }}
                    >
                      <Icon
                        name="dropdown"
                        className={classNames(
                          "transition text-gray group-hover:text-gold w-6 h-6 transform rotate-90",
                          { "opacity-20": currentSlide <= 0 }
                        )}
                      />
                    </button>
                  </span>
                  <span className="px-6 flex justify-center items-center border-t-2 border-b-2 border-solid border-gray-light">
                    <button
                      type="button"
                      className="text-blue hover:text-gold transition"
                      onClick={() => setOpen(false)}
                    >
                      <Text variant="title">View Full Team</Text>
                    </button>
                  </span>
                  <span className="mr-auto md:mr-0">
                    <button
                      type="button"
                      className="border-2 border-l-0 border-solid border-gray-light relative inline-flex justify-center items-center h-10 px-3 cursor-pointer bg-transparent flex-row  active:opacity-75 group transition text-light"
                      onClick={() => {
                        setCurrentSlide(prevState => {
                          if (prevState < team?.length - 1) {
                            return prevState + 1;
                          }
                          return prevState;
                        });
                      }}
                    >
                      <Icon
                        name="dropdown"
                        className={classNames(
                          "transition text-gray group-hover:text-gold w-6 h-6 transform -rotate-90",
                          { "opacity-20": currentSlide >= team?.length - 1 }
                        )}
                      />
                    </button>
                  </span>
                </div>

                <Carousel
                  activeSlide={[currentSlide, setCurrentSlide]}
                  hideArrows
                >
                  {team?.map(member => {
                    const { image } = member;
                    return (
                      <PersonCard key={member.uid} aboutModal {...member} />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

TeamModal.defaultProps = {};

export default TeamModal;
