import React from "react";
import { motion } from "framer-motion";

/**
 *
 * `AnimateIn` animates child elements when they enter the viewport
 *
 */

const presets = {
  fadeUpFast: {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeOut",
      },
    },
  },
  fadeInFast: {
    offscreen: {
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeOut",
      },
    },
    onscreen: {
      opacity: 1,
    },
  },
};

const AnimateIn = ({
  preset,
  threshold,
  onEnter,
  onLeave,
  children,
  className,
}) => {
  return (
    <motion.div
      variants={presets[preset]}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: threshold }}
      onViewportEnter={onEnter}
      onViewportLeave={onLeave}
      className={`AnimateIn ${className}`}
    >
      {children}
    </motion.div>
  );
};

AnimateIn.defaultProps = {
  preset: "fadeUpFast",
  threshold: 0.5,
  className: "",
};

export default AnimateIn;
