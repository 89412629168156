import React from "react";
import { Text, Icon } from "@atoms";
import { useFooterData } from "@staticQueries";

const IconWrapper = ({ children }) => (
  <div className="flex items-center justify-center border-2 border-blue rounded-full p-3 text-blue">
    {children}
  </div>
);

const ContactDetails = () => {
  const { email, phone, location, hours } = useFooterData();
  return (
    <div className="flex flex-wrap -mx-2">
      <div className="flex w-full md:w-1/2 xl:w-1/4 flex-nowrap items-center p-2">
        <IconWrapper>
          <Icon name="email" className="w-4 h-4" />
        </IconWrapper>
        <Text
          variant="xs"
          className="ml-2 md:ml-4 text-gray whitespace-pre-line"
        >
          {email}
        </Text>
      </div>
      <div className="flex w-full md:w-1/2 xl:w-1/4 flex-nowrap items-center p-2">
        <IconWrapper>
          <Icon name="phone" className="w-4 h-4" fitHeight />
        </IconWrapper>
        <Text
          variant="xs"
          className="ml-2 md:ml-4 text-gray whitespace-pre-line"
        >
          {phone}
        </Text>
      </div>
      <div className="flex w-full md:w-1/2 xl:w-1/4 flex-nowrap items-center p-2">
        <IconWrapper>
          <Icon name="mailbox" className="w-4 h-4" />
        </IconWrapper>
        <Text
          variant="xs"
          className="ml-2 md:ml-4 text-gray whitespace-pre-line"
        >
          {location}
        </Text>
      </div>
      <div className="flex w-full md:w-1/2 xl:w-1/4 flex-nowrap items-center p-2">
        <IconWrapper>
          <Icon name="clock" className="w-4 h-4" />
        </IconWrapper>
        <Text
          variant="xs"
          className="ml-2 md:ml-4 text-gray whitespace-pre-line"
        >
          {hours}
        </Text>
      </div>
    </div>
  );
};

ContactDetails.defaultProps = {};

export default ContactDetails;
