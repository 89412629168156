import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query RecentBlogArticlesQuery {
    craft {
      entries(section: "blog", limit: 6) {
        ... on Craft_blog_blog_Entry {
          uid
          title
          postDate
          url
        }
      }
    }
  }
`;

// const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const articles = craft.entries.map(a => {
    return {
      title: a.title,
      date: a.postDate,
      url: a.url,
    };
  });

  return articles;
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
