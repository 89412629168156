import React from "react";
import { Text, Button } from "@atoms";

const EventCard = ({ title, copy, date, fee, link, image }) => {
  return (
    <div className="p-8 border-t-3 border-gold bg-white shadow-lg mt-8">
      <Text variant="h4" className="uppercase text-blue">
        {title}
      </Text>
      <div className="mt-8 space-y-4">
        <div className="md:flex text-gray">
          <div className="flex-shrink-0 md:w-24 mr-8">
            <Text variant="xs" className="font-bold">
              Overview:
            </Text>
          </div>
          <div className="flex-1">
            <Text variant="xs" className="text-gray">
              {copy}
            </Text>
          </div>
        </div>
        <div className="md:flex text-gray">
          <div className="flex-shrink-0 md:w-24 mr-8">
            <Text variant="xs" className="font-bold">
              Date & Time:
            </Text>
          </div>
          <div className="flex-1">
            <Text variant="xs" className="text-gray">
              {date}
            </Text>
          </div>
        </div>
        <div className="md:flex text-gray">
          <div className="flex-shrink-0 md:w-24 mr-8">
            <Text variant="xs" className="font-bold">
              Fee:
            </Text>
          </div>
          <div className="flex-1">
            <Text variant="xs" className="text-gray">
              {fee}
            </Text>
            {link?.url && (
              <Button to={link.url} size="sm" className="mt-8">
                {link.text || "Register"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EventCard.defaultProps = {};

export default EventCard;
