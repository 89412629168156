import React from "react";
import { HighlightText } from "@molecules";
import { CbWrapper, Image } from "@atoms";

const CbCompanies = ({ type, companies }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-wrap -mx-6">
        <div className="w-full lg:w-1/3 px-6">
          <HighlightText variant="h2--small">{`Our\nPartners`}</HighlightText>
        </div>
        <div className="w-full lg:w-2/3 px-6 mt-6 lg:mt-0">
          <div className="flex flex-wrap justify-between -mx-6">
            {companies.map(c => {
              return (
                <div
                  className="w-1/2 sm:w-1/3 relative px-6 mb-12 h-12"
                  key={c.id}
                >
                  <Image image={c.image} objectFit="contain" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </CbWrapper>
  );
};

CbCompanies.defaultProps = {};

export default CbCompanies;
