import React from "react";

import { CbWrapper, Image, Button, Text } from "@atoms";

const CbPdfs = ({ type, files }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-wrap -mx-2">
        {files.map(f => {
          return (
            <div
              key={f.id}
              className="w-32 flex flex-col justify-end space-y-4 text-center p-2 flex-shrink-0"
            >
              <Text variant="xxs" className="line-clamp-2">
                {f.title}
              </Text>
              <div className="relative w-full h-36">
                <Image image={f} ixParams={{ page: 1 }} fill transparent />
              </div>
              <Button size="sm" to={f.url}>
                View PDF
              </Button>
            </div>
          );
        })}
      </div>
    </CbWrapper>
  );
};

CbPdfs.defaultProps = {};

export default CbPdfs;
