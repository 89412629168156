import React from "react";
import { Text } from "@atoms";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const PieChartBlock = ({ title, data: _data }) => {
  const data = _data.map(d => ({
    label: d.xAxisLabel,
    value: d.dataValue,
    fill: d.dataColor,
  }));
  return (
    <div className="w-full">
      <Text variant="h4" className="text-blue">
        {title}
      </Text>
      <div className="flex flex-wrap items-center mt-4 -mx-3">
        <div className="w-full sm:w-1/2 px-3">
          <ResponsiveContainer width="100%" height={320} debounce={5}>
            <PieChart>
              <Pie
                data={data}
                innerRadius="33%"
                // label={({ index }) => data[index].xAxisLabel}
              >
                {data.map((d, i) => {
                  const { fill } = d;
                  return <Cell fill={fill} strokeWidth={4} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full sm:w-1/2 space-y-4 px-3">
          {data.map((d, ii) => {
            return (
              <div className="flex items-center">
                <div
                  className="w-2 h-2 inline-block rounded-full"
                  style={{ backgroundColor: d.fill }}
                />
                <span className="ml-2 text-xs text-gray leading-tight">
                  {d.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PieChartBlock.defaultProps = {};

export default PieChartBlock;
