/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable import/prefer-default-export */
/* eslint react/prop-types: 0 */

import React from "react";
import { AnimatePresence } from "framer-motion";
import { AppStateProvider } from "@state/state";
import { Default } from "@layout";
import "./src/pcss/index.pcss";

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter initial={false}>
    {element}
  </AnimatePresence>
);

export const wrapRootElement = ({ element }) => {
  return (
    <AppStateProvider>
      <Default>{element}</Default>
    </AppStateProvider>
  );
};
