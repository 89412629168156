import resolveImage from "./resolveImage";

const resolveStats = stats =>
  stats.map(s => {
    return {
      id: s.uid,
      icon: resolveImage(s.statsIcon),
      value: s.statsValue,
      label: s.statsLabel,
    };
  });

// const resolveDataPoints = data => {
//   data.map(d => {
//     return {
//       stat: d.xAxisLabel,
//       number: d.yAxisLabel,
//       value: d.dataValue,
//       color: d.dataColor,
//     }
//   })
// }

const resolveGraphs = graphs =>
  graphs.map(g => {
    return {
      title: g.title,
      type: g.graphsType,
      data: g.graphsDataPoints,
    };
  });

const resolveStatBlocks = statBlocks =>
  statBlocks.map(block => {
    const { typeHandle: type } = block;
    switch (type) {
      case "dataImageBgBlock":
        return {
          type,
          heading: block.imageBgTitle,
          stats: resolveStats(block.imageBgStats),
          image: resolveImage(block.imageBgImage),
        };
      case "dataWhiteBgBlock":
        return {
          type,
          heading: block.whiteBgTitle,
          stats: resolveStats(block.whiteBgStats),
          link1: {
            text: block.whiteBgCTA1.text,
            url: block.whiteBgCTA1.url,
          },
          link2: {
            text: block.whiteBgCTA2.text,
            url: block.whiteBgCTA2.url,
          },
          graphs: resolveGraphs(block.whiteBgGraphs),
        };
      default:
        return null;
    }
  });

export default resolveStatBlocks;
