import React from "react";
import { HighlightText } from "@molecules";
import { Text } from "@atoms";

const Heading = ({ heading, copy, small }) => {
  return (
    <div className="flex flex-wrap md:flex-nowrap text-center md:text-left items-center">
      <div className="w-full max-w-sm break-words whitespace-pre-line mx-auto md:mx-0 md:pr-6">
        <HighlightText variant={small ? "h2--small" : "h2"}>
          {heading}
        </HighlightText>
      </div>
      {copy && (
        <div className="max-w-lg mt-6 mx-12 md:m-0 p-0 md:pl-4 md:py-2 border-blue md:border-l-2 text-gray">
          <Text variant={small ? "sm" : "lg"}>{copy}</Text>
        </div>
      )}
    </div>
  );
};

Heading.defaultProps = {};

export default Heading;
