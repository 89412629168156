import React from "react";
import { Text, StatBar } from "@atoms";

const BarGraph = ({ title, data }) => {
  return (
    <div className="w-full">
      <Text variant="h4" className="text-blue">
        {title}
      </Text>
      <div className="flex flex-wrap space-y-1 mt-4">
        <StatBar data={data} />
      </div>
    </div>
  );
};

export default BarGraph;
