import React from "react";

const CbContainer = ({ children }) => {
  return (
    <div className="bg-white border-t-2 border-gold shadow-lg p-4 pt-8 sm:p-8 md:p-12 overflow-hidden">
      <div className="md:w-2/3 md:mx-auto">{children}</div>
    </div>
  );
};

CbContainer.defaultProps = {};

export default CbContainer;
