import React from "react";
import classNames from "classnames";
import { Text, Button, Image } from "@atoms";

// displays image on right
const ColorBlock = ({ copy, link, image, flip }) => {
  return (
    <div
      className={classNames(
        "relative flex w-full min-h-[18rem] flex-wrap-reverse bg-blue",
        {
          "flex-row md:flex-row-reverse": flip,
          "flex-row-reverse md:flex-row": !flip,
        }
      )}
    >
      <div className="flex flex-col justify-center items-start w-full md:w-1/2 p-12">
        <Text variant="xl" className="text-white">
          {copy}
        </Text>
        {link?.url && (
          <div className="flex justify-start mt-4">
            <Button to={link.url} size="md" className="mt-4" color="white">
              {link.text || "Learn More"}
            </Button>
          </div>
        )}
      </div>

      <div className="relative w-full md:w-1/2 h-48 sm:h-64 md:h-auto">
        <div className="absolute inset-0 overflow-hidden">
          <Image image={image} fill position="center" />
        </div>
      </div>
    </div>
  );
};

ColorBlock.defaultProps = {};

export default ColorBlock;
