import React from "react";
import { Heading } from "@molecules";
import { Container, Text, Image } from "@atoms";
import { AppLink } from "@base";

const CountyCities = ({
  heading,
  copy,
  citiesHeading,
  areasHeading,
  image,
  cities,
  areas,
}) => {
  return (
    <div className="bg-gray-light py-16 md:py-24">
      <Container variant="xl">
        <Heading heading={heading} copy={copy} />
        <div className="flex flex-wrap md:flex-nowrap bg-white mt-16 md:mt-24">
          <div className="relative w-full md:w-1/2 h-48 md:h-auto md:p-4 overflow-hidden">
            <div className="h-full w-full relative">
              <Image image={image} fill />
            </div>
          </div>
          <div className="w-full md:w-1/2 p-8 md:p-4">
            <Text variant="title" className="text-blue">
              {citiesHeading}
            </Text>
            {cities.length && (
              <div className="grid grid-cols-2 gap-y-4 gap-x-4 md:gap-x-8 mt-4">
                {cities.map(city => (
                  <div>
                    <AppLink
                      to={city.url}
                      className="text-xs text-gray hover:text-blue hover:underline-gold transition duration-300"
                    >
                      {city.name}
                    </AppLink>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {areas && (
          <div className="flex bg-white mt-8">
            <div className="w-full p-8 md:p-4">
              <Text variant="title" className="text-blue">
                {areasHeading}
              </Text>
              {areas.length && (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-4 gap-x-4 md:gap-x-8 mt-4">
                  {areas.map(area => (
                    <div>
                      <span className="text-xs text-gray">{area.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

CountyCities.defaultProps = {};

export default CountyCities;
