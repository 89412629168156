import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Header, ShieldBackground, Search } from "@organisms";
import { loadGTM } from "@utils";
import BrowserWarning from "../atoms/BrowserWarning";

const DefaultLayout = ({ children, path }) => {
  useEffect(() => {
    loadGTM();
  }, []);

  // the layout
  return (
    <div id="app_layout" className="overflow-visible overflow-x-hidden">
      <Helmet>
        {/* Google Fonts */}
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css?family=Fjalla+One|Merriweather+Sans&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Fjalla+One|Merriweather+Sans&display=swap"
        />
        {/* uncomment to load typekit */}
        {/* <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} /> */}
        {/* uncomment to allow social embeds */}
        {/* <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" /> */}
      </Helmet>
      <Header />
      <ShieldBackground />
      {children}
      <BrowserWarning />
      <Search />
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

export default DefaultLayout;
