import React from "react";
import { ContentBuilder } from "@organisms";
import { FormSidebar } from "@molecules";
import { Container, Text } from "@atoms";

const ContentForm = ({ heading, contentBuilder, form }) => {
  return (
    <Container variant="xl" className="mt-36 lg:mt-48">
      <div className="flex flex-wrap -mx-6">
        <div className="w-full lg:w-2/3 px-6">
          <Text variant="h1" className="text-blue">
            {heading}
          </Text>
          <ContentBuilder blocks={contentBuilder} />
        </div>
        <div className="w-full lg:w-1/3 px-6">
          <FormSidebar {...form} />
        </div>
      </div>
    </Container>
  );
};

export default ContentForm;
