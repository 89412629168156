import React from "react";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const Share = ({ email, title, url }) => {
  const urls = {
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
  };

  return (
    <div className="flex items-center space-x-4">
      <span className="text-xs text-blue">Share:</span>
      <AppLink to={urls.LinkedIn} target="_blank">
        <Icon
          name="linkedin"
          className="w-4 h-4 text-blue hover:text-gold transition duration-300"
        />
      </AppLink>
      <AppLink to={urls.Twitter} target="_blank">
        <Icon
          name="twitter"
          className="w-4 h-4 text-blue hover:text-gold transition duration-300"
        />
      </AppLink>
      {email && (
        <AppLink
          to={`mailto:?subject=${encodeURIComponent(
            title
          )}&body=${encodeURIComponent(url)}`}
        >
          <Icon
            name="email"
            className="w-4 h-4 text-blue hover:text-gold transition duration-300"
          />
        </AppLink>
      )}
    </div>
  );
};

Share.defaultProps = {
  email: true,
};

export default Share;
