import React from "react";
import { Heading } from "@molecules";
import { Container, Stat, Image } from "@atoms";

const QuickStats = ({ heading, stats, featuredStats, image }) => {
  return (
    <div className="relative pb-16 md:pb-24">
      <Container variant="xl" className="relative z-20">
        <Heading heading={heading} />
        <div className="relative flex flex-col items-end w-full mt-6 md:mt-0 mx-auto">
          {stats && (
            <div className="w-full lg:w-4/5 flex flex-wrap justify-center">
              {stats.map((stat, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stat {...stat} key={i} count={3} />
              ))}
            </div>
          )}
          {featuredStats && (
            <div className="w-full lg:w-4/5 flex flex-wrap justify-center">
              {featuredStats.map((stat, ii) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stat {...stat} key={ii} count={featuredStats.length} />
              ))}
            </div>
          )}
        </div>
      </Container>
      <div className="absolute inset-0 z-10 overflow-hidden">
        <div className="absolute left-0 right-0 top-0 -bottom-2/3 md:-bottom-full">
          <Image image={image} fill />
        </div>
      </div>
    </div>
  );
};

QuickStats.defaultProps = {};

export default QuickStats;
