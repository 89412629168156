import React from "react";
import { Icon } from "@atoms";

const ShieldBackground = () => {
  return (
    <div className="z-[-1] absolute inset-0 flex items-center justify-center">
      <Icon
        name="arrowhead"
        className="w-1/3 sm:w-1/4 opacity-50 text-gold-dark animate-pulse"
      />
    </div>
  );
};

ShieldBackground.defaultProps = {};

export default ShieldBackground;
