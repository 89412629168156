import React, { useState } from "react";
import classNames from "classnames";
import CardGrid from "./CardGrid";
import TeamModal from "./TeamModal";

const OurTeam = ({ team }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [open, setOpen] = useState(false);

  return (
    <>
      <CardGrid
        icons={false}
        {...team}
        openModal={[open, setOpen]}
        activeSlide={[currentSlide, setCurrentSlide]}
      />
      <TeamModal
        team={team?.cards}
        openModal={[open, setOpen]}
        activeSlide={[currentSlide, setCurrentSlide]}
      />
    </>
  );
};

OurTeam.defaultProps = {};

export default OurTeam;
