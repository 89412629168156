import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "contactInfo") {
        ... on Craft_contactInfo_GlobalSet {
          contactCountyLink
          contactCountyLinkTextMobile
          contactCountyLinkTextDesktop
        }
      }
      entries(type: "nav", level: 1, navShowInHeader: true) {
        ... on Craft_nav_nav_Entry {
          id
          title
          navLink {
            title
            url
          }
          navShowInHeader
          navShowInFooter
          children(navShowInHeader: true) {
            ... on Craft_nav_nav_Entry {
              id
              title
              navLink {
                title
                url
              }
              navShowInHeader
              navShowInFooter
            }
          }
        }
      }
    }
  }
`;

// const previewQuery = ``;

const resolveNav = item => {
  return {
    id: item.id,
    link: {
      text: item.title,
      url: item.navLink[0].url,
    },
    level: item.level,
  };
};

const dataResolver = ({ craft }) => {
  return {
    contactCounty: {
      url: craft.globalSet.contactCountyLink,
      mobile: craft.globalSet.contactCountyLinkTextMobile,
      desktop: craft.globalSet.contactCountyLinkTextDesktop,
    },
    nav: craft.entries.map(item => {
      return {
        id: item.id,
        link: {
          text: item.title,
          url: item.navLink[0].url,
        },
        level: item.level,
        children: item.children.map(resolveNav),
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
