import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query AllEventsQuery {
    craft {
      entries(section: "events") {
        ... on Craft_events_events_Entry {
          title
          eventDate
          eventEndTime
          url
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const events = craft.entries.map(e => {
    const convertedEndTime = `${e?.eventDate?.split("T")[0]}T${
      e?.eventEndTime?.split("T")[1]
    }`;

    return {
      title: e.title,
      date: e.eventDate.split("T")[0],
      time: e.eventDate.split("T")[1],
      start: e.eventDate,
      end: e.eventEndTime ? convertedEndTime : null,
      url: e.url,
    };
  });

  return events;
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
