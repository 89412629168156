import React from "react";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const SocialBar = ({ heading, icons }) => {
  return (
    <div className="relative bg-blue pl-4 md:pl-8 pr-4 py-8 md:py-4 h-24">
      <div className="relative z-10 flex flex-nowrap items-center">
        <Text className="text-3xl md:text-[3.5rem] font-title uppercase leading-none text-white">
          {heading}
        </Text>
        <div className="flex flex-nowrap ml-auto space-x-2 md:space-x-6">
          {icons?.map((icon, i) => {
            let name;
            switch (icon?.socialIconName) {
              case "twitter":
                name = "twitterCircle";
                break;
              case "youtube":
                name = "youtube";
                break;
              case "linkedin":
                name = "linkedinCircle";
                break;
              default:
                break;
            }

            return (
              <AppLink
                to={icon?.socialIconLink}
                className="w-6 sm:w-8 lg:w-12 h-6 md:h-8 lg:h-12 inline-block group"
              >
                <Icon
                  name={name}
                  className="w-full text-white group-hover:text-gold transition duration-300"
                />
              </AppLink>
            );
          })}
        </div>
      </div>
      <div className="absolute w-full top-0 bottom-0 md:-right-full bg-blue" />
    </div>
  );
};

SocialBar.defaultProps = {};

export default SocialBar;
