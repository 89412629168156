import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Text, Button } from "@atoms";
import { motion, useAnimation } from "framer-motion";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";

const EmailForm = ({ heading, url, condensed, type }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const errorMessage = useAnimation();
  const successMessage = useAnimation();

  function encode(data) {
    // Swapping to formData to seeing if that'll actually store the image
    // in Netlify's forms
    const formatData = new FormData();
    Object.keys(data).forEach(key => {
      if (key === "resume") {
        for (const file of data[key]) {
          formatData.append(key, file, file.name);
        }
      } else {
        formatData.append(key, data[key]);
      }
    });
    return formatData;
  }

  useEffect(() => {
    setFormData({
      ...formData,
      type: heading,
      honeypot: "required-name",
    });
  }, []);

  useEffect(() => {
    if (error && error.length) {
      errorMessage.start({
        height: "auto",
        opacity: 1,
        transition: {
          duration: 0.3,
          type: "tween",
          ease: "easeInOut",
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      successMessage.start({
        height: "auto",
        opacity: 1,
        transition: {
          duration: 0.3,
          type: "tween",
          ease: "easeInOut",
        },
        // easing: "back",
      });
    }
  }, [success]);

  const submitForm = e => {
    e.preventDefault();
    if (!formData.type || formData.type !== heading) {
      setSuccess(true);
      return;
    }
    // if (!formData["g-recaptcha-response"]) {
    //   setError("reCAPTCHA required.");
    //   return;
    // }
    if (formData && !success) {
      const send = encode({
        "form-name": e.target.getAttribute("name"),
        ...formData,
      });
      axios
        .post(`/`, send, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(() => {
          axios.post(
            `/.netlify/functions/mailchimp`,
            JSON.stringify(formData),
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            }
          );
        })
        .then(() => setSuccess(true))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <div
      className={classNames("w-full", {
        "shadow-lg bg-white p-6": type === "newsletter",
        "py-6": type === "download",
      })}
    >
      {!condensed && (
        <Text variant="xxl" className="text-blue font-bold mb-6">
          {heading}
        </Text>
      )}

      <div className="relative transition duration-300">
        <form
          id="newsletter-footer"
          data-netlify="true"
          data-netlify-recaptcha="true"
          data-netlify-honeypot="required-name"
          name="Newsletter Form"
          onSubmit={e => {
            e.preventDefault();
            submitForm(e);
            if (url) {
              window.open(url, "_blank");
            }
          }}
        >
          <input type="hidden" name="form-name" value="Newsletter Form" />
          <motion.div
            animate={errorMessage}
            initial={{
              height: 0,
              opacity: 0,
              transition: {
                duration: 0.3,
                type: "tween",
                ease: "easeInOut",
              },
            }}
            className="flex justify-start sm:justify-center w-full overflow-hidden"
          >
            <Text variant="sm" className="pb-8 text-red">
              {error}
            </Text>
          </motion.div>
          <div
            className={classNames("mb-4", {
              "sm:flex": !condensed,
            })}
          >
            <div
              className={classNames("mb-4", {
                "flex-1 sm:pr-4 sm:w-1/2 sm:mb-0": !condensed,
              })}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="firstName" className="block mb-2">
                <Text variant="label" className="text-gray">
                  First name
                </Text>
              </label>
              <div className="sm:flex">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  required
                  placeholder="Required field"
                  onChange={e => {
                    setFormData({ ...formData, firstName: e.target.value });
                  }}
                  className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
                />
              </div>
            </div>
            <div
              className={classNames("mb-0", {
                "flex-1 sm:pl-4 sm:w-1/2": !condensed,
              })}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="lastName" className="block mb-2">
                <Text variant="label" className="text-gray">
                  Last name
                </Text>
              </label>
              <div className="sm:flex">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  required
                  placeholder="Required field"
                  onChange={e => {
                    setFormData({ ...formData, lastName: e.target.value });
                  }}
                  className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
                />
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="email" className="block mb-2">
            <Text variant="label" className="text-gray">
              Email
            </Text>
          </label>
          <div
            className={classNames({
              "sm:flex": !condensed,
              "flex flex-wrap justify-center": condensed,
            })}
          >
            <input
              type="email"
              name="email"
              id="email"
              required
              placeholder="Required field"
              onChange={e => {
                setFormData({ ...formData, email: e.target.value });
              }}
              className="bg-gray-light px-2 w-full h-10 text-sm placeholder-gray"
            />
            <div
              className={classNames("mt-8", {
                "sm:flex-initial sm:mt-0": !condensed,
              })}
            >
              <Button type="submit" size="sm" className="h-10">
                Submit
              </Button>
            </div>
          </div>
          {/* <div className="flex justify-start sm:justify-end mt-8">
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onChange={e => {
                setFormData({ ...formData, "g-recaptcha-response": e });
              }}
            />
          </div> */}
          <motion.div
            animate={successMessage}
            initial={{
              height: 0,
              opacity: 0,
              transition: {
                duration: 0.3,
                type: "tween",
                ease: "easeInOut",
              },
            }}
            className="mt-2 flex justify-start sm:justify-center w-full"
          >
            <Text variant="sm" className="mt-2 py-4 text-blue">
              {type === "newsletter"
                ? "Thank you for subscribing to our newsletter."
                : "Thank you for submitting the form."}
            </Text>
          </motion.div>
        </form>
      </div>
    </div>
  );
};

EmailForm.defaultProps = {
  type: "newsletter",
};

export default EmailForm;
