const resolveImages = images => {
  if (images && images.length > 0) {
    return images.map(image => {
      const {
        status,
        title,
        url,
        height,
        caption,
        width,
        hasFocalPoint,
        focalPoint,
      } = image;
      return {
        status,
        title,
        caption,
        url,
        height,
        width,
        focalPoint: hasFocalPoint ? focalPoint : false,
        fpx: hasFocalPoint ? focalPoint[0] : null,
        fpy: hasFocalPoint ? focalPoint[1] : null,
      };
    });
  }
  return null;
};

export default resolveImages;
