import React from "react";
import { Text, Image, Button, Icon } from "@atoms";
import { AppLink } from "@base";

const IconCard = ({ image, heading, copy, link }) => {
  return (
    <AppLink
      to={link.url}
      className="border-t-3 border-transparent box-border hover:border-gold transition duration-300"
    >
      <div className="p-6 bg-white shadow-md min-h-[12rem] h-full">
        <div className="flex flex-col justify-end items-center text-center h-full">
          {link.url === "/contact" ? (
            <Icon
              name="cities"
              className="text-blue w-16 h-12"
              fitHeight
              placeholder={false}
            />
          ) : (
            <div className="w-full h-12 relative">
              <Image image={image} objectFit="contain" fill transparent />
            </div>
          )}

          <Text variant="title" className="text-blue mt-4">
            {heading}
          </Text>
          <Text variant="xs" className="text-gray mt-4 mb-8">
            {copy}
          </Text>
          <Button size="sm" className="mt-auto" to={link.url}>
            {link.text || "Learn More"}
          </Button>
        </div>
      </div>
    </AppLink>
  );
};

IconCard.defaultProps = {};

export default IconCard;
