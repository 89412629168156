/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Icon } from "@atoms";

const Pagination = ({ articleAmount, pageState, visibleState }) => {
  // eslint-disable-next-line no-unused-vars
  const [visiblePosts, setVisiblePosts] = visibleState || useState(9);
  const [currentPage, setCurrentPage] = pageState || useState(1);

  const firstPage = currentPage === 1;
  const lastPage = currentPage > articleAmount / visiblePosts;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {articleAmount > 9 && (
        <div className="flex justify-center mt-8 divide-x-2 divide-gray-dusty">
          <span>
            <button
              type="button"
              aria-label={`Go to page ${currentPage - 1}`}
              className="border-2 border-r-0 border-solid border-gray-dusty relative inline-flex justify-center items-center h-12 px-6 cursor-pointer bg-transparent flex-row  active:opacity-75 group transition text-blue"
              onClick={() => {
                if (!firstPage) setCurrentPage(currentPage - 1);
              }}
            >
              <Icon
                name="dropdown"
                className="transition text-blue group-hover:text-gold w-6 h-6 transform rotate-90"
              />
            </button>
          </span>
          <span>
            <button
              type="button"
              aria-label={`Go to page ${currentPage + 1}`}
              className="border-2 border-l-0 border-solid border-gray-dusty relative inline-flex justify-center items-center h-12 px-6 cursor-pointer bg-transparent flex-row  active:opacity-75 group transition text-blue"
              onClick={() => {
                if (!lastPage) setCurrentPage(currentPage + 1);
              }}
            >
              <Icon
                name="dropdown"
                className="transition text-blue group-hover:text-gold w-6 h-6 transform -rotate-90"
              />
            </button>
          </span>
        </div>
      )}
    </>
  );
};

Pagination.defaultProps = {};

export default Pagination;
