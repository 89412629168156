import React from "react";
import { Container } from "@atoms";
import { Heading, UpcomingEvents, EmailForm, SocialBar } from "@molecules";
import { usePreFooterData } from "@staticQueries";

const Newsletter = () => {
  const { heading, upcomingEvents, newsletter, social } = usePreFooterData();
  return (
    <section className="relative triangle-bg-br py-16 md:py-24">
      <Container variant="xl" className="relative">
        <Heading heading={heading} />
        <div className="relative flex flex-wrap lg:flex-nowrap -mx-6 mt-16">
          {/* Upcoming Events box */}
          <div className="px-6 w-full lg:w-2/5">
            <UpcomingEvents {...upcomingEvents} />
          </div>
          <div className="mt-6 lg:mt-0 px-6 w-full lg:w-3/5 space-y-6 lg:space-y-12">
            <EmailForm {...newsletter} />
            <SocialBar {...social} />
          </div>
        </div>
      </Container>
    </section>
  );
};

Newsletter.defaultProps = {};

export default Newsletter;
