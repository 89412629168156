import React from "react";

import { CbWrapper, Image } from "@atoms";

const CbImage = ({ image, type }) => {
  return (
    <CbWrapper type={type}>
      <Image image={image} />
    </CbWrapper>
  );
};

CbImage.defaultProps = {};

export default CbImage;
