import React from "react";
import { Text, CbWrapper } from "@atoms";

const CbCopy = ({ copy, type }) => {
  return (
    <CbWrapper type={type}>
      <div className="absolute w-1/2 top-0 bottom-0 -left-1/2 bg-gray-light" />
      <div className="py-6 pr-6 bg-gray-light relative z-10">
        <Text
          variant="body"
          className="text-gray border-l-2 border-blue pullquote pl-4"
        >
          {copy}
        </Text>
      </div>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
