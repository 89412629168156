import React from "react";
import classNames from "classnames";
import { Container, Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { useFooterData } from "@staticQueries";

const Footer = () => {
  // const [{ layout }, dispatch] = useAppState();
  // const { showNav } = layout;

  const currentYear = new Date().getFullYear();
  const { email, emailSubject, phone, socials, location, nav } =
    useFooterData();

  return (
    <footer className="relative z-50">
      <section className="py-12 bg-blue w-full">
        <Container
          variant="xl"
          className="flex flex-col md:flex-row items-start justify-between md:gap-12"
        >
          <div className="flex flex-col mx-auto mb-12 md:mb-0 justify-center text-center items-center">
            <div className="w-10 h-auto flex-shrink-0 inline-block mb-8 md:mx-12">
              <Icon name="arrowhead" className="w-full text-gray" />
            </div>
            <div className="flex flex-row md:flex-col gap-4">
              {socials?.map((social, i) => {
                let icon;
                switch (social?.socialIconName) {
                  case "twitter":
                    icon = "twitterCircle";
                    break;
                  case "youtube":
                    icon = "youtube";
                    break;
                  case "linkedin":
                    icon = "linkedinCircle";
                    break;
                  default:
                    break;
                }

                return (
                  <AppLink
                    to={social?.socialIconLink}
                    className="w-6 h-6 inline-block group"
                  >
                    <Icon
                      name={icon}
                      className="w-full text-white group-hover:text-gold transition duration-300"
                    />
                  </AppLink>
                );
              })}
            </div>
          </div>
          <div className="w-full">
            <nav className="w-full">
              <ul className="flex lg:flex-1 flex-wrap md:flex-nowrap text-center md:text-left justify-between">
                {nav?.map((item, i) => {
                  const link = item?.link;
                  const { url, text } = link;
                  const { children } = item;

                  return (
                    <li
                      className="w-full sm:w-1/2 md:w-auto relative group block md:mr-8 md:last:mr-0 pb-8 md:pb-0"
                      key={i}
                    >
                      {url && (
                        <AppLink
                          to={url}
                          noBottomPadding
                          className="relative z-20"
                        >
                          <Text variant="title--sm" className="text-gold">
                            {text}
                          </Text>
                        </AppLink>
                      )}
                      <ul className={classNames("space-y-4", { "pt-4 ": url })}>
                        {children?.map((subItem, ii) => {
                          return (
                            <li key={ii}>
                              <AppLink to={subItem?.link?.url} noBottomPadding>
                                <Text
                                  className={classNames(
                                    "text-white hover:underline-gold transition duration-300 pb-[.1rem]",
                                    { "text-xs md:text-xxs mt-4": url }
                                  )}
                                >
                                  {subItem?.link?.text}
                                </Text>
                              </AppLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </Container>
      </section>

      <section className="py-12 bg-blue-dark w-full">
        <Container variant="xl">
          <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-8">
            <Text className="text-xs md:text-xxs text-center text-white block">{`&copy; San Bernardino County ${currentYear}`}</Text>
            <div className="flex flex-col items-center gap-4">
              {email && (
                <AppLink
                  to={`mailto:${email}?subject=${emailSubject}`}
                  className="flex items-center group"
                >
                  <Icon
                    name="email"
                    className="w-4 h-auto text-white group-hover:text-gold transition duration-300 mr-2"
                  />
                  <Text className="text-xs md:text-xxs text-center text-white group-hover:text-gold transition duration-300">
                    {email}
                  </Text>
                </AppLink>
              )}
              <AppLink to={`tel:${phone}`} className="flex items-center group">
                <Icon
                  name="phone"
                  className="w-3 h-4 text-white group-hover:text-gold transition duration-300 mr-2"
                />
                <Text className="text-xs md:text-xxs text-center text-white group-hover:text-gold transition duration-300">
                  {phone}
                </Text>
              </AppLink>
            </div>
            <div className="flex flex-col items-center gap-4">
              <AppLink
                to={`https://www.google.com/maps/place/${encodeURI(location)}`}
                className="flex items-center group"
              >
                <Text className="leading-loose text-xs md:text-xxs text-center text-white group-hover:text-gold transition duration-300">
                  {location?.replace("\n", "<br>")}
                </Text>
              </AppLink>
            </div>
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
