import React from "react";
import classNames from "classnames";
import { Container } from "@atoms";
import { Heading, ImageCard } from "@molecules";

const HomeImageGrid = ({ heading, copy, blocks }) => {
  return (
    <section className="py-16 md:py-24 bg-gray-light">
      <Container variant="xl">
        <Heading heading={heading} copy={copy} />
        <div
          className={classNames(
            "grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12",
            {
              "mt-16": heading,
            }
          )}
        >
          {blocks && (
            <>
              {/* top card */}
              <ImageCard full {...blocks[0]} />
              {/* bottom cards */}
              <ImageCard {...blocks[1]} />
              <ImageCard {...blocks[2]} />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default HomeImageGrid;
