import layout from "./reducers/layout";
import createAppState from "./lib/createAppState";

const reducer = {
  layout: layout.reducer,
};

const initialState = {
  layout: layout.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
