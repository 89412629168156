import React from "react";
import { formatDate } from "@utils";
import { AppLink } from "@base";
import { Text, Image } from "@atoms";

const ArticlePreview = ({ image, url, date, categories, title }) => {
  return (
    <li className="border-t-3 border-transparent box-border hover:border-gold transition duration-300 p-4 shadow-lg space-y-2 bg-white">
      <AppLink to={url}>
        <Image image={image} ratio={3 / 2} />
      </AppLink>
      <Text variant="xxs" className="text-gray">
        {formatDate(date, "MM/dd/yyyy")}
      </Text>
      <Text variant="xxs" className="text-gray">
        {`Category: ${categories}`}
      </Text>
      <AppLink to={url} className="group block">
        <Text className="text-base leading-relaxed text-blue hover:underline-gold transition duration-300">
          {title}
        </Text>
      </AppLink>
    </li>
  );
};

export default ArticlePreview;
