import React from "react";
import { Carousel, Feature } from "@molecules";
import { Container } from "@atoms";

const FeatureCarousel = ({ carousel }) => {
  return (
    <Container variant="xl" className="mt-16">
      {carousel?.length > 1 && (
        <div className="-mx-1">
          <Carousel maxVisible={2} centerItems showMultiple itemsStart>
            {carousel.map(slide => (
              <Feature {...slide} key={slide.uid} />
            ))}
          </Carousel>
        </div>
      )}
    </Container>
  );
};

export default FeatureCarousel;
