import resolveImage from "./resolveImage";

const resolveCompanies = companies =>
  companies.map(company => {
    return {
      id: company.id,
      image: resolveImage(company.companyLogo),
    };
  });

const resolveCB = contentBuilder =>
  contentBuilder.map(block => {
    const { typeHandle: type } = block;
    switch (type) {
      case "cbParagraph":
        return {
          type,
          copy: block.paragraph,
        };
      case "cbHeading":
        return {
          type,
          heading: block.heading,
          copy: block.paragraph,
        };
      case "cbPullQuote":
        return {
          type,
          copy: block.pullQuote,
        };
      case "cbImage":
        return {
          type,
          image: resolveImage(block.image),
        };
      case "cbVideo":
        return {
          type,
          subheading: block.subheading,
          heading: block.heading,
          description: block.description,
          provider: block.videoType,
          id: block.videoId,
        };
      case "cbLinks":
        return {
          type,
          links: block.links,
        };
      case "cbCta":
        return {
          type,
          title: block.ctaTitle,
          subtitle: block.ctaSubtitle,
          url: block.ctaLink.url,
          image: resolveImage(block.ctaBackgroundImage),
        };
      case "cbEmbed":
        return {
          type,
          code: block.code,
        };
      case "cbCompanies":
        return {
          type,
          companies: resolveCompanies(block.companyLogos),
        };
      case "instagramPreview":
        return {
          type,
        };
      case "cbPdfs":
        return {
          type,
          files: block.files,
        };
      case "cbContactCard":
        return {
          type,
          teamMember: {
            name: block.person[0].teamMember[0].fullName,
            image: resolveImage(block.person[0].teamMember[0].image),
            linkedIn: block.person[0].teamMember[0].contactInfo?.[0].linkedin,
            title: block.person[0].teamMember[0].position,
            email: block.person[0].teamMember[0].email,
          },
          cta: block.cta,
          link: {
            text: block.linkLink.text,
            url: block.linkLink.url,
          },
        };
      default:
        return null;
    }
  });

export default resolveCB;
