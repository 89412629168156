import React, { useEffect } from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { useAppState } from "@state/state";
import { Icon } from "@atoms";
import { useWindowSize } from "@utils";
import tailwindConfig from "@theme";

const MobileHeader = ({ nav }) => {
  const [{ layout }, dispatch] = useAppState();
  const { hideNav } = layout;

  // get screen sizes
  const { screens } = tailwindConfig.theme;
  // get windows size
  const { innerWidth: windowSize } = useWindowSize();

  useEffect(() => {
    if (hideNav) {
      document.body.style = "";
    } else {
      document.body.style.setProperty("width", "100%", "important");
      document.body.style.setProperty("max-height", "100%", "important");
      document.body.style.setProperty("overflow", "hidden", "important");
      document.body.style.setProperty("margin", "0", "important");
    }
  }, [hideNav]);

  useEffect(() => {
    const lgScreen = parseInt(screens.lg.replace("px", ""), 10);
    if (windowSize >= lgScreen && !hideNav) {
      dispatch({
        type: "hideNav",
      });
    }
  }, [windowSize]);

  return (
    <div
      className={classNames(
        "w-screen h-screen overflow-visible overflow-x-hidden absolute inset-0 top-16 md:top-20 z-0",
        { "pointer-events-none": hideNav }
      )}
    >
      <div
        className={classNames(
          "transition duration-300 transform min-h-full h-full w-full bg-gray-light overflow-y-scroll pt-4 pb-32",
          { "opacity-100 translate-x-0 z-50": !hideNav },
          { "opacity-0 translate-x-full ": hideNav }
        )}
      >
        <ul>
          {nav.map(item => {
            const link = item?.link;
            const { url, text } = link;
            const { children } = item;

            return (
              <li
                className="relative group block text-center items-center justify-center py-4"
                key={item.id}
              >
                <span className="relative z-20">
                  <AppLink
                    to={url}
                    className={classNames(
                      "font-title uppercase text-blue inline-flex items-center",
                      {
                        "transition duration-300 border-b-3 border-transparent hover:border-gold focus:border-gold":
                          !children?.length,
                      }
                    )}
                    onClick={() => {
                      dispatch({
                        type: "hideNav",
                      });
                    }}
                    activeClassName=""
                  >
                    {text}
                  </AppLink>
                </span>
                {children?.length > 0 && (
                  <ul className="transition duration-300 space-y-6 mt-6 mb-6 text-center">
                    {children?.map(subItem => {
                      return (
                        <li key={subItem.id}>
                          <AppLink
                            to={subItem?.link?.url}
                            className="font-title uppercase text-gray hover:underline-gold focus:underline-gold hover:text-blue transition duration-300"
                            onClick={() => {
                              dispatch({
                                type: "hideNav",
                              });
                            }}
                          >
                            {subItem?.link?.text}
                          </AppLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
          <li className="relative group block text-center items-center justify-center py-4">
            <AppLink
              className="font-title uppercase text-blue inline-flex items-center space-x-2"
              onClick={() => {
                dispatch({
                  type: "showSearch",
                });
              }}
            >
              <span>Search</span>
              <Icon
                name="search"
                className="w-3.5 h-3.5 text-blue hover:text-gold transition duration-300"
              />
            </AppLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileHeader;
