import React from "react";
import { Container } from "@atoms";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

const EventList = ({ events }) => {
  return (
    <Container variant="xl" className="py-16 md:py-24">
      <div className="flex justify-between">
        {/* View buttons */}
        {/* <DropDown
        placeholder="categories"
        model={[category, setCategory]}
        options={categories}
      /> */}
      </div>
      {events && (
        <FullCalendar
          // height="100%"
          width="100%"
          contentHeight="auto"
          events={events}
          plugins={[listPlugin, dayGridPlugin]}
          initialView="listMonth"
          // eventContent={EventContent}
          // eslint-disable-next-line react/jsx-no-bind
          // eventClick={dayClick}
          buttonText={{
            today: "today",
            listMonth: "list",
            dayGridMonth: "calendar",
          }}
          headerToolbar={{
            start: "listMonth,dayGridMonth", // will normally be on the left. if RTL, will be on the right
            center: "title",
            end: "prev,today,next", // will normally be on the right. if RTL, will be on the left
          }}
          // timeZone="America/Los_Angeles"
        />
      )}
    </Container>
  );
};

export default EventList;
