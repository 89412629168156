import React from "react";
import { Heading, InstagramPost } from "@molecules";
import { Container } from "@atoms";
import { useInstagramData } from "@staticQueries";

const InstagramFeed = () => {
  const { heading, copy, posts } = useInstagramData();
  return (
    <div className="py-16">
      <Container variant="xl" className="pb-12">
        <Heading heading={heading} copy={copy} small />
      </Container>
      {posts && (
        <div className="overflow-hidden">
          <ul className="flex flex-wrap justify-between sm:flex-nowrap items-center -mx-[5%]">
            {posts.map(post => (
              <li className="w-1/4 pb-[25%] z-[2] relative transform skew-x-[15deg]">
                <InstagramPost {...post} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

InstagramFeed.defaultProps = {};

export default InstagramFeed;
