import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query CategoriesQuery {
    craft {
      categories {
        uid
        id
        title
        slug
        uri
        url
      }
    }
  }
`;

// const previewQuery = ``;

const dataResolver = ({ craft }) => {
  return {
    categories: craft?.categories,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
