import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  showIcon,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={classNames(
        "inline-flex items-start relative z-0 group transition duration-300 border-2 border-gray hover:border-gold group-hover:border-gold px-6 py-3",
        _className
      )}
    >
      {/* back arrow */}
      {showIcon && reverse && (
        <span
          className={classNames(
            "icon h-3 w-3 transform flex-shink-0 mr-1 group-hover:text-gold transition duration-300",
            {
              "rotate-90": icon === "triangle",
            }
          )}
        >
          <Icon name={icon} />
        </span>
      )}
      {/* label */}
      <span className="uppercase font-title text-sm tracking-wide leading-tighter">
        {children}
      </span>
      {/* forward arrow */}
      {showIcon && !reverse && (
        <span
          className={classNames(
            "icon h-3 w-3 flex-shink-0 ml-1 group-hover:text-gold transition duration-300",
            {
              "-rotate-90": icon === "triangle",
            }
          )}
        >
          <Icon name={icon} />
        </span>
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  showIcon: false,
  icon: "triangle",
};

export default ButtonAlt;
