import React, { useRef, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import classNames from "classnames";
import { useWindowSize } from "@utils";
import { Icon } from "@atoms";
import tailwindConfig from "@theme";

const Carousel = ({
  children,
  indicators,
  maxVisible,
  activeSlide,
  hideArrows,
}) => {
  const [currentSlide, setCurrentSlide] = activeSlide || useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.toArray(children);
  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const { screens } = tailwindConfig.theme;

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x } = info.offset;
    requestAnimationFrame(() => {
      if (x < -slideWidth / slideCount) {
        setCurrentSlide(prevState => {
          if (prevState < slides.length - visibleSlides) {
            return prevState + 1;
          }
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
          return prevState;
        });
      } else if (x > slideWidth / slideCount) {
        setCurrentSlide(prevState => {
          if (prevState > 0) {
            return prevState - 1;
          }
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
          return prevState;
        });
      } else {
        carouselControls.start({
          x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
        });
      }
    });
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen) {
        return noSlides[matchedScreen] <= maxVisible
          ? noSlides[matchedScreen]
          : maxVisible;
      }
      // else return 2
      return maxVisible;
    }
    return 1;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth]);

  // calculate visible slides on window resize
  // ? should this be consolidated with the above hook ?
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div ref={carouselContainer} className="relative w-full">
        {/* prev button */}
        {!hideArrows && (
          <div className="absolute -left-12 top-0 bottom-0 transform translate-y-50 items-center justify-center z-10 hidden md:flex">
            <button
              className={classNames(
                "flex items-center justify-center group transition duration-300 bg-gold text-white p-4",
                {
                  "opacity-20": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
            >
              <Icon
                name="arrow"
                className="w-4 h-4 transform rotate-180 group-hover:-translate-x-1.5 transition duration-300 relative right-0"
              />
            </button>
          </div>
        )}

        <motion.div
          animate={carouselControls}
          className="flex"
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag="x"
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
        >
          {slides.map((slide, i) => (
            <div
              className={classNames("relative")}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </motion.div>
        {/* next button */}
        {!hideArrows && (
          <div className="absolute -right-12 top-0 bottom-0 items-center justify-center z-10 hidden md:flex">
            <button
              className={classNames(
                "flex items-center justify-center group transition duration-300 bg-gold text-white p-4",
                {
                  "opacity-20": currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
            >
              <Icon
                name="arrow"
                className="w-4 h-4 group-hover:translate-x-1.5 transition duration-300 relative right-0"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && (
        <ul className="flex items-center justify-center">
          {slides.map((slide, i) => (
            <li>
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={classNames(
                  "bg-white h-2 w-2 rounded-full block mx-1 transition duration-300",
                  {
                    "opacity-50": currentSlide !== i,
                  }
                )}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
};

export default Carousel;
