import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "contactInfo") {
        ... on Craft_contactInfo_GlobalSet {
          email {
            ... on Craft_email_emailblock_BlockType {
              emailAddress
              emailSubject
            }
          }
          phone
          commonLocation {
            ... on Craft_commonLocation_commonLocationBlock_BlockType {
              commonLocationBlockAddress
            }
          }
          officeHours
        }
      }
      social: globalSet(handle: "footerGlobal") {
        ... on Craft_footerGlobal_GlobalSet {
          footerSocialIcons {
            ... on Craft_footerSocialIcons_socialIconSelector_BlockType {
              socialIconName
              socialIconLink
            }
          }
        }
      }
      entries(type: "nav", level: 1, navShowInFooter: true) {
        ... on Craft_nav_nav_Entry {
          id
          title
          navLink {
            title
            url
          }
          navShowInHeader
          navShowInFooter
          children(navShowInFooter: true) {
            ... on Craft_nav_nav_Entry {
              id
              title
              navLink {
                title
                url
              }
              navShowInHeader
              navShowInFooter
            }
          }
        }
      }
    }
  }
`;

// const previewQuery = ``;

const resolveNav = item => {
  return {
    id: item.id,
    link: {
      text: item.title,
      url: item.navLink[0].url,
    },
    level: item.level,
  };
};

const dataResolver = ({ craft }) => {
  return {
    email: craft.globalSet?.email?.[0]?.emailAddress,
    emailSubject: craft.globalSet?.email?.[0]?.emailSubject,
    phone: craft.globalSet.phone,
    location: craft.globalSet.commonLocation[0].commonLocationBlockAddress,
    hours: craft.globalSet.officeHours,
    socials: craft.social.footerSocialIcons,
    nav: craft.entries.map(item => {
      return {
        id: item.id,
        link: {
          text: item.title,
          url: item.navLink[0].url,
        },
        level: item.level,
        children: item.children.map(resolveNav),
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
