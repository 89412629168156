import React from "react";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
}) => {
  let Tag = "span";
  let classes = "text-base";
  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-title uppercase text-4xl md:text-[3.5rem] leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-title uppercase text-4xl md:text-[3.5rem] leading-tighter";
      break;
    case "h2--small":
      Tag = "h2";
      classes = "font-title uppercase text-3xl md:text-4xl leading-tigher";
      break;
    case "h2--sans":
      Tag = "h2";
      classes = "font-sans text-xl md:text-2xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-title text-2xl leading-tighter";
      break;
    case "h3--sans":
      Tag = "h3";
      classes = "font-sans text-2xl leading-normal";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-title text-lg leading-tighter";
      break;
    case "h4--sans":
      Tag = "h4";
      classes = "font-sans text-lg leading-tighter";
      break;
    case "xxl":
      classes = "block text-1-5xl md:text-2xl leading-normal font-sans";
      break;
    case "xl":
      classes = "block texl-lg md:text-xl leading-loose font-sans";
      break;
    case "lg":
      classes = "block text-base md:text-lg leading-relaxed font-sans";
      break;
    case "body":
      classes = "block text-sm md:text-base leading-loose font-sans";
      break;
    case "body--small":
      classes = "block text-sm leading-loose font-sans";
      break;
    case "body--xsmall":
      classes = "block text-xs leading-loose font-sans";
      break;
    case "title":
      classes = "text-xl md:text-base leading-normal font-title uppercase";
      break;
    case "sm":
      classes = "block text-sm leading-relaxed";
      break;
    case "title--sm":
      classes = "text-sm leading-tight font-title uppercase";
      break;
    case "xs":
      classes = "block text-xs leading-loose";
      break;
    case "xs--tight":
      classes = "block text-xs leading-normal";
      break;
    case "xxs":
      classes = "block text-xs sm:text-xxs leading-relaxed";
      break;
    case "label":
      classes = "block text-xs leading-none font-sans uppercase tracking-tight";
      break;
    default:
      classes = "";
      break;
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`
            ${classes} 
            ${_className} 
            ${!richText ? "" : "rich-text"} 
            ${quotes ? "quotes" : ""} 
            `}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        className={`
          ${classes} 
          ${_className} 
          ${!richText ? "" : "rich-text"} 
          ${quotes ? "quotes" : ""} 
        `}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: true,
  quotes: false,
  children: null,
};

export default Text;
