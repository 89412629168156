import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query UpcomingEventsQuery {
    craft {
      globalSet(handle: "footerGlobal") {
        ... on Craft_footerGlobal_GlobalSet {
          footerStayCurrentHeader
          #events
          footerEventsHeader
          #newsletter
          footerNewsletterHeader
          formSuccessNewsletter
          #social
          footerFollowHeader
          footerSocialIcons {
            ... on Craft_footerSocialIcons_socialIconSelector_BlockType {
              socialIconName
              socialIconLink
            }
          }
          #cta
          commonCopy
          commonButton {
            ... on Craft_linkField_Link {
              text
              url
            }
          }
          commonImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(
        section: "events"
        limit: 3
        eventDate: ">= now"
        orderBy: "postDate asc"
      ) {
        ... on Craft_events_events_Entry {
          eventDate
          title
          url
        }
      }
    }
  }
`;

// const previewQuery = `
// `;

const dataResolver = ({ craft }) => {
  const {
    footerEventsHeader,
    footerStayCurrentHeader,
    footerNewsletterHeader,
    footerFollowHeader,
    footerSocialIcons,
    commonCopy,
    commonButton,
    commonImage,
  } = craft.globalSet;
  const events = craft.entries.map(e => {
    return {
      date: e.eventDate.split("T")[0],
      title: e.title,
      url: e.url,
    };
  });
  return {
    heading: footerStayCurrentHeader,
    upcomingEvents: {
      heading: footerEventsHeader,
      events,
    },
    newsletter: {
      heading: footerNewsletterHeader,
    },
    social: {
      heading: footerFollowHeader,
      icons: footerSocialIcons,
    },
    contact: {
      copy: commonCopy,
      link: {
        text: commonButton.text,
        url: commonButton.url,
      },
      image: resolveImage(commonImage),
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
