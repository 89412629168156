import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query InstagramQuery {
    craft {
      globalSet(handle: "instagramFeed") {
        ... on Craft_instagramFeed_GlobalSet {
          instagramDescription
          instagramUrl
          instagramFeed {
            ... on Craft_instagramFeed_post_BlockType {
              postUrl
              postImg {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
        }
      }
    }
  }
`;

// const previewQuery = `
//   query InstagramQuery {
//     craft {
//       globalSet(handle: "instagramFeed") {
//         ... on Craft_instagramFeed_GlobalSet {
//           instagramDescription
//           instagramUrl
//           instagramFeed {
//             ... on Craft_instagramFeed_post_BlockType {
//               postUrl
//               postImg {
//                 width
//                 height
//                 hasFocalPoint
//                 slug
//                 status
//                 title
//                 focalPoint
//                 url
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const dataResolver = ({ craft }) => {
  const { instagramDescription, instagramUrl, instagramFeed } = craft.globalSet;
  const resolvePosts = posts =>
    posts.map(p => {
      return {
        url: p.postUrl?.length ? p.postUrl : instagramUrl,
        image: resolveImage(p.postImg),
      };
    });
  return {
    heading: `Get
    Inspired`,
    copy: instagramDescription,
    url: instagramUrl,
    posts: resolvePosts(instagramFeed),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
