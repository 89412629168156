import React from "react";
import classNames from "classnames";
import { Text } from "@atoms";

const HighlightedText = ({
  children,
  className: _className,
  style: _style,
  variant,
  fill,
  hero,
  ...rest
}) => {
  // check for heading and split each word at line break
  const segments = children?.split("\n");
  if (segments?.length > 1) {
    return (
      <div className={classNames("block", _className)}>
        {/* Render text before the highlight */}
        <Text
          variant={variant}
          className={classNames({
            "text-white md:text-blue": !fill && hero,
            "text-blue": !hero,
            "text-white text-shadow": fill,
          })}
          style={_style}
          {...rest}
        >
          {segments.slice(0, segments.length - 1)}
        </Text>

        {/* Render text after the highlight */}
        {segments.at(-1)?.length > 0 && (
          <Text
            variant={variant}
            className={classNames("text-gold", {
              "text-shadow": fill,
            })}
            style={_style}
            {...rest}
          >
            {segments.at(-1)}
          </Text>
        )}
      </div>
    );
  }

  // if it's only one word, return that word in gold
  return (
    <Text
      variant={variant}
      className={classNames("text-gold", _className)}
      style={_style}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default HighlightedText;
