import React from "react";
import { Text } from "@atoms";

const Label = ({ xAxisLabel }) => {
  return (
    <Text variant="xs" className="text-gray truncate">
      {xAxisLabel}
    </Text>
  );
};

const Bar = ({ yAxisLabel, dataColor, width }) => {
  return (
    <div
      className="h-3 w-full relative right-0 top-0 flex items-center"
      style={{ width: `${width}%`, backgroundColor: dataColor }}
    >
      <div className="pl-2 md:pl-4 absolute left-full">
        <Text variant="xs" className="text-left text-gray truncate">
          {yAxisLabel}
        </Text>
      </div>
    </div>
  );
};

const StatBar = ({ data }) => {
  const dataValues = data?.map((d, i) => d?.dataValue)?.sort((a, b) => a - b);
  const min = dataValues[0];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const max = dataValues[dataValues?.length - 1];
  const range = max - min;
  const amount = data?.length;
  const padding = Math.floor(range / amount);

  return (
    <div className="flex justify-start items-center w-full">
      <div className="flex flex-col max-w-[40%] pr-2 md:pr-4">
        {data?.map(d => {
          return <Label {...d} />;
        })}
      </div>
      <div className="flex flex-col flex-grow space-y-3 relative w-full max-w-md lg:max-w-none">
        {data?.map(d => {
          return (
            <Bar
              {...d}
              width={((d.dataValue - min + padding) / (range + padding)) * 60}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StatBar;
