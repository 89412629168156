import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import algoliasearch from "algoliasearch";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";

const Search = () => {
  // get app state for search
  const [{ layout }, dispatch] = useAppState();
  const { showSearch } = layout;
  // Search state
  const [query, setQuery] = useState();
  const [results, setResults] = useState([]);
  // search field ref
  const searchInput = useRef();

  // set up algolia
  const siteClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_SITE_APP_ID,
    process.env.GATSBY_ALGOLIA_SITE_SEARCH_KEY
  );
  const siteIndex = siteClient.initIndex(process.env.GATSBY_ALGOLIA_SITE_INDEX);

  // perform a new search when query changes
  useEffect(() => {
    if (query?.length) {
      // search pages
      siteIndex
        .search(query)
        .then(({ hits }) => {
          setResults(
            previousResult =>
              ({
                ...previousResult,
                pages: hits,
              }.pages)
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      setResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!showSearch) {
      setQuery("");
      document.body.style = "";
    } else {
      document.body.style.setProperty("max-height", "100%", "important");
      document.body.style.setProperty("overflow", "hidden", "important");
      document.body.style.setProperty("margin", "0", "important");
    }
  }, [showSearch]);

  return (
    <div
      className={classNames(
        "fixed w-full h-screen bg-blue bg-opacity-90 top-0 left-0 z-50 pt-16 md:pt-24 transition duration-300",
        { "opacity-100 pointer-events-auto": showSearch },
        { "opacity-0 pointer-events-none": !showSearch }
      )}
    >
      {/* Things go here! */}
      <button
        type="button"
        className="absolute p-6 top-0 right-0"
        onClick={() => {
          dispatch({
            type: "hideSearch",
          });
        }}
      >
        <Icon name="close" className="text-gold w-6 h-6" />
      </button>

      <form className="w-full max-w-md mx-auto px-6 flex items-center">
        <input
          ref={searchInput}
          onChange={() => setQuery(searchInput.current.value)}
          onInput={() => setQuery(searchInput.current.value)}
          className="outline-none rounded-none flex-1 border-0 border-b-2 border-solid border-white text-lg md:text-xl text-white p-2 bg-transparent"
        />
        <Icon name="searchAlt" className="w-6 h-6 md:w-10 md:h-10 text-white" />
      </form>

      <div className="h-full overflow-y-auto">
        <div className="max-w-md w-full mx-auto my-0 pt-2 pb-12 px-6">
          {!!query?.length && !!results?.length && (
            <ul>
              {results?.map((r, i) => {
                return (
                  <li className="group py-3">
                    <AppLink
                      to={r?.url}
                      className="text-white inline-flex flex-col"
                    >
                      <Text
                        variant="xs"
                        className="font-bold group-hover:text-gold transition duration-300"
                      >
                        {r?.title}
                      </Text>
                      <Text variant="xs">
                        {r?._snippetResult?.content?.overview?.value}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          )}
          {!!query?.length && !results?.length && (
            <Text variant="xs" className="text-gray-silver py-2">
              No results
            </Text>
          )}
          {!query?.length && (
            <Text variant="xs" className="text-gray-silver py-2">
              Begin typing to see results
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Search.defaultProps = {};

export default Search;
