import React from "react";
import { Text, CbWrapper } from "@atoms";

const CbLinks = ({ links, type }) => {
  return (
    <CbWrapper type={type}>
      <div className="link-list">
        <Text className="text-xs leading-normal">{links}</Text>
      </div>
    </CbWrapper>
  );
};

CbLinks.defaultProps = {};

export default CbLinks;
