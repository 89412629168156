import React from "react";
import { Text, Container, Button, Icon, Image } from "@atoms";

const HomeOverview = ({ image, copy, features, button }) => {
  return (
    <section className="relative">
      <Container variant="xl" className="relative h-auto py-16">
        {/* intro text */}
        <div className="relative z-30">
          <div className="block mx-auto opacity-25 w-20 h-20 p-4 border-2 border-gray rounded-full">
            <Icon name="arrowhead" className="w-full text-gray" />
          </div>
          <Text
            variant="xl"
            className="w-full max-w-2xl mt-12 mx-auto text-center text-gray"
          >
            {copy}
          </Text>
        </div>
        {/* features */}
        <div className="relative z-30">
          <div className="flex flex-wrap justify-center -mx-6 mt-24">
            {features?.map(feature => (
              <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center text-center space-y-4 text-white p-6">
                <div className="w-12 md:w-16 h-12 h:16 relative">
                  <Image
                    image={feature.icon}
                    objectFit="contain"
                    fill
                    transparent
                  />
                </div>
                <Text variant="title">{feature.title}</Text>
                <Text variant="xs">{feature.copy}</Text>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-12">
            <Button color="white" to={button.url}>
              {button.text}
            </Button>
          </div>
        </div>
      </Container>
      {/* background image */}
      <div className="absolute inset-0 z-10 overflow-hidden">
        <div className="absolute left-0 right-0 top-0 -bottom-1/4 md:-bottom-1/2">
          <Image image={image} fill />
        </div>
      </div>
    </section>
  );
};

HomeOverview.defaultProps = {};

export default HomeOverview;
