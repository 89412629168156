import React, { useState } from "react";
import classNames from "classnames";
import { Text, Icon, Image } from "@atoms";
import { AppLink } from "@base";

const PersonCard = ({
  image,
  name,
  title,
  linkedIn,
  email,
  horizontal,
  bio,
  aboutModal,
  activeSlide,
  openModal,
  index,
}) => {
  const [currentSlide, setCurrentSlide] = activeSlide || useState(0);
  const [open, setOpen] = openModal || useState(false);
  return (
    <div
      className={classNames("relative flex justify-end", {
        "flex-col space-y-3": !horizontal && !aboutModal,
        "items-center space-x-4": horizontal,
        "flex-col md:flex-row space-y-3 md:space-y-0 md:items-start space-x-0 md:space-x-4 overflow-auto scrolling-touch mt-24 md:mt-0 pb-10 md:pd-0 px-6 md:px-0 !justify-start":
          aboutModal,
      })}
    >
      {!horizontal && !aboutModal && !openModal && !activeSlide && (
        <Image
          ixParams={{ fit: "crop", crop: "faces" }}
          image={image}
          ratio={1 / 1}
        />
      )}

      {!horizontal && !aboutModal && openModal && activeSlide && (
        <button
          type="button"
          onClick={() => {
            setOpen(true);
            setCurrentSlide(index);
          }}
          aria-label={`View ${name}'s bio`}
        >
          <Image
            ixParams={{ fit: "crop", crop: "faces" }}
            image={image}
            ratio={1 / 1}
          />
        </button>
      )}
      {(horizontal || aboutModal) && (
        <div
          className={classNames({
            "w-1/3": !aboutModal,
            "text-center md:text-left w-48 mx-auto md:w-1/3": aboutModal,
          })}
        >
          <Image
            ixParams={{ fit: "crop", crop: "faces" }}
            image={image}
            ratio={1 / 1}
          />

          {aboutModal && (
            <div className="mt-4 space-y-4">
              {linkedIn && (
                <AppLink to={linkedIn} className="w-4 h-4">
                  <Icon name="linkedin" className="w-4 h-4 text-blue" />
                </AppLink>
              )}
              <AppLink
                to={`mailto:${email}`}
                className="hover:text-blue transition duration-300 truncate"
              >
                <Text variant="xxs" className="text-gray">
                  {email}
                </Text>
              </AppLink>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames("flex flex-col", {
          "w-full md:w-2/3": aboutModal || horizontal,
          "pt-6 md:pt-0": aboutModal,
        })}
      >
        <div className="flex items-center justify-start">
          <Text variant="title" className="text-blue">
            {name}
          </Text>
          {linkedIn && !aboutModal && (
            <AppLink to={linkedIn} className="w-4 h-4 ml-2 md:ml-4">
              <Icon name="linkedin" className="w-full text-blue" />
            </AppLink>
          )}
        </div>
        <Text variant="xs" className="text-gray font-bold">
          {title}
        </Text>
        {aboutModal && bio && (
          <Text variant="xs" className="text-gray font-bold mt-4">
            {bio}
          </Text>
        )}
        {!aboutModal && (
          <AppLink
            to={`mailto:${email}`}
            className="hover:text-blue transition duration-300 truncate"
          >
            <Text variant="xxs" className="text-gray">
              {email}
            </Text>
          </AppLink>
        )}
      </div>
    </div>
  );
};

PersonCard.defaultProps = {};

export default PersonCard;
