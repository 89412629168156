import React from "react";
import { Text, Image } from "@atoms";
import { EmailForm } from "@molecules";

const FormSidebar = ({ heading, image, url }) => {
  return (
    <div className="flex flex-col items-start space-y-4 bg-white p-4 shadow-md">
      {heading && (
        <Text variant="h4--sans" className="text-gold font-bold">
          {heading}
        </Text>
      )}
      {image && <Image image={image} ratio={4 / 3} />}
      <EmailForm condensed type="download" url={url} />
    </div>
  );
};

export default FormSidebar;
