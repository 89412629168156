import React from "react";
import { useRecentArticlesData } from "@staticQueries";
import { Text } from "@atoms";
import { AppLink } from "@base";
import { formatDate } from "@utils";

const RecentArticles = ({ uid }) => {
  const articles = useRecentArticlesData()
    .filter(a => a.uid !== uid)
    .slice(0, 5);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {articles && (
        <div className="bg-white shadow-lg mt-4">
          {articles?.map(a => (
            <div className="p-4 border-t-3 border-gold space-y-2 text-gray">
              <Text variant="xxs">{formatDate(a.date, "MMMM dd, yyyy")}</Text>
              <AppLink to={a.url} className="group inline-block">
                <span className="text-sm inline leading-relaxed hover:underline-gold hover:text-blue transition duration-300">
                  {a.title}
                </span>
              </AppLink>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RecentArticles;
