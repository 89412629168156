import React from "react";
import classNames from "classnames";
import { HighlightText } from "@molecules";
import { Container, Text, Image } from "@atoms";

const PageHero = ({ label, heading, subhead, image, fill }) => {
  return (
    <div
      className={classNames({
        "bg-gray-light": !fill,
        "bg-white": fill,
      })}
    >
      <div
        className={classNames(
          "relative h-full z-10 pt-16 lg:pt-20 min-h-[25vw]",
          {
            "triangle-bg-tl": !fill,
            "shadow-xl": fill,
          }
        )}
      >
        <Container
          variant="xl"
          className={classNames("h-auto", {
            "md:h-72 lg:h-96": fill,
          })}
        >
          <div className="flex justify-between h-full">
            <div className="relative flex flex-col justify-center z-30 py-8 md:py-0">
              <div className="max-w-md break-words whitespace-pre-line">
                <Text
                  className={classNames("block text-white font-bold mb-4", {
                    "md:text-gray": !fill,
                    "text-shadow": fill,
                  })}
                >
                  {label}
                </Text>
                <HighlightText variant="h1" fill={fill} hero>
                  {heading}
                </HighlightText>
              </div>
            </div>
            {!fill && (
              <div className="hidden md:block w-1/2 h-full py-16">
                <div className="relative h-full w-full shadow-xl">
                  <Image image={image} ratio={4 / 3} />
                </div>
              </div>
            )}
          </div>
        </Container>
        {/* hero image */}
        {fill && (
          <>
            <div className="absolute mt-16 md:mt-20 inset-0 overflow-hidden w-full">
              <Image
                eager
                image={image}
                fill
                ixParams={{ exp: "-5", con: "-5" }}
              />
            </div>
            <div className="hidden md:block absolute inset-0 z-20 mt-16 md:mt-20 triangle-bg-tr" />
          </>
        )}
        {!fill && (
          <>
            {/* mobile bg */}
            <div className="block md:hidden absolute mt-16 inset-0 shadow-xl overflow-hidden w-full">
              <Image
                eager
                image={image}
                fill
                ixParams={{ exp: "-5", con: "-5" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PageHero.defaultProps = {};

export default PageHero;
