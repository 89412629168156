import React from "react";
import { BarGraph, PieChart } from "@molecules";
import { Container, Text, Button, Stat } from "@atoms";

const StatBlock = ({ heading, graphs, link1, link2, stats }) => {
  return (
    <Container
      variant="2xl"
      noHorizontalPadding
      className="relative z-20 px-0 sm:px-6"
    >
      <div className="p-16 bg-white shadow-xl -my-8 sm:-my-12">
        <div className="border-b-2 border-gold pb-4">
          <Text
            variant="h2--sans"
            className="block text-blue text-center font-bold"
          >
            {heading}
          </Text>
        </div>
        <div className="flex flex-wrap mt-8 justify-center -mx-4 space-y-8 lg:space-y-0">
          <div className="w-full lg:w-1/2 px-4">
            <BarGraph {...graphs[0]} />
          </div>
          <div className="flex w-full lg:w-1/2 justify-center px-4">
            {stats[0] && <Stat {...stats[0]} inverse />}
            {graphs[1] && <PieChart {...graphs[1]} />}
          </div>
        </div>
        {link1?.url && (
          <div className="flex justify-center mt-8">
            <Button to={link1.url}>{link1.text}</Button>
          </div>
        )}
        {link2?.url && (
          <div className="flex justify-center mt-8">
            <Button to={link2.url}>{link2.text}</Button>
          </div>
        )}
      </div>
    </Container>
  );
};

StatBlock.defaultProps = {};

export default StatBlock;
